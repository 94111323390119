import { Reducer } from 'redux'
import * as actions from './actions'
import { ConfigState, LoadStatus } from './types'
import { getInitState, mergeWithDefaultInstance } from './utils'

export const configReducer: Reducer<ConfigState, actions.ConfigActions> = (state = getInitState(), action) => {
  switch (action.type) {
    case actions.SET_APP_CONFIG: {
      const { config: c } = action.payload

      return {
        ...state,
        appConfigLoadStatus: LoadStatus.LOADED,
        // app config setup:
        url: c.url ?? '',
        recaptcha: c.recaptcha,
        mapsKey: c.mapsKey,
        matomo: {
          siteId: c.matomo?.siteId,
          freemiumSiteId: c.matomo?.freemiumSiteId,
          urlBase: c.matomo?.urlBase,
          trackedUrls: c.matomo?.trackedUrls
        },
        productFruitsKey: c.productFruitsKey,
        provisionalProductFruitsKey: c.provisionalProductFruitsKey,
        chatBotTestOrganizationCodes: c.chatBotTestOrganizationCodes,
        usercentricsSettingsId: c.usercentricsSettingsId,
        showReactQueryDevTool: c.showReactQueryDevTool ?? false,
        samlAuth: c.samlAuth,
        freemiumReturnUrl: c.freemiumReturnUrl,
        default: mergeWithDefaultInstance(c.default)
      }
    }
    case actions.SET_FAIL_APP_CONFIG: {
      return {
        ...state,
        appConfigLoadStatus: LoadStatus.FAILED
      }
    }
    case actions.SET_USER_CONFIG: {
      const { userConfig } = action.payload
      switch (userConfig.userConfigLoadStatus) {
        case LoadStatus.FAILED:
        case LoadStatus.LOADING:
        case LoadStatus.NOT_LOADED: {
          return {
            ...state,
            userConfigLoadStatus: userConfig.userConfigLoadStatus
          }
        }
        case LoadStatus.LOADED:
          return {
            ...state,
            userConfigLoadStatus: userConfig.userConfigLoadStatus,
            instance: userConfig.instance
          }
        default:
          return state
      }
    }
    case actions.SET_SIGNALR_STARTED:
      return {
        ...state,
        isSignalRStarted: action.payload.started
      }
    case actions.SET_APP_LANGUAGE:
      return {
        ...state,
        appLanguage: action.payload.language
      }
    case actions.CONFIG_AFTER_LOGOUT:
      return {
        ...state,
        userConfigLoadStatus: LoadStatus.NOT_LOADED,
        instance: undefined
      }
    default:
      return state
  }
}
