/* eslint-disable import/no-default-export */
export default {
  Title: 'Suchergebnisse',
  Search: 'Suche',
  SearchPlaceholder: 'Suche',
  SearchCompaniesAdditionalInfo: 'Bitte beachten Sie, dass Sie für Unternehmen auch den',
  SearchCompaniesAdditionalInfoAutChe:
    'Bitte beachten Sie dies für österreichische Unternehmen auch den Firmenname, UID-Nr./MWST-Nr., Website URL, Firmenbuchnummer. Für Schweizer Unternehmen auch den UID-Nr./MWST-Nr., Unternehmens-Identifikationsnummer, Firmenname, Website URL, CH Key',
  NoResults: 'Wir konnten leider keine Übereinstimmungen finden für',
  TryDifferent:
    'Versuchen Sie es mit einem anderen Suchbegriff oder verwenden Sie die <0>semantische Suche</0>, um nach verwandten Begriffen zu suchen.',
  TryDifferentWithoutProsp: 'Versuchen Sie es mit einem anderen Suchbegriff',
  Banner: {
    title: 'Aktivieren Sie den Bereich "Prospecting"',
    subtitle: 'Die semantische Suche ist nur für Benutzer verfügbar, die den Abschnitt "Prospecting" gekauft haben.',
    gotoAction: 'Kontaktieren Sie {{companyName}}'
  },
  Tabs: {
    Companies: 'Unternehmen',
    Portfolios: 'Portfolios',
    Campaigns: 'Kampagnen'
  },
  SearchInformationDialog: {
    Title: 'Was kann gesucht werden?',
    SubTitleWithCampaigns: 'Sie können nach Unternehmen, Portfolios oder Kampagnen suchen',
    SubTitle: 'Sie können nach Unternehmen oder Portfolios suchen',
    CompaniesBy: '<strong>Unternehmen</strong> nach:',
    PortfoliosCampaignsHelpText: '<strong>Portfolios</strong> und <strong>Kampagnen</strong> nach Namen',
    PortfoliosHelpText: '<strong>Portfolios</strong> nach Namen',
    Companies: {
      CommercialDisplay: 'Kommerzielle Anzeige',
      Swiss: {
        VatCode: 'UID-Nr./MWST-Nr.',
        IdentificationNumber: 'Unternehmens-Identifikationsnummer',
        CompanyName: 'Firmenname',
        Website: 'Website URL',
        LegacyCHNumber: 'CH Key',
        CompanyBookNumber: 'Firmenbuchnummer'
      },
      Germany: {
        VatCode: 'VATcode',
        TaxId: 'Tax id',
        Onr: 'ONR',
        Website: 'Website'
      }
    },
    Countries: {
      ch: 'SCHWEIZERISCH',
      at: 'ÖSTERREICH'
    }
  },
  HiddenPortfolioCompany:
    'Auf das gesuchte Unternehmen kann nicht zugegriffen werden, da es möglicherweise zu einer Brieftasche gehört, die von einem anderen Benutzer in Ihrer Organisation ausgeblendet wurde.',
  Company: 'Unternehmen',
  Portfolio: 'Portfolio',
  Campaign: 'Kampagnen',
  GoTo: {
    Company: 'Firmenbericht',
    Portfolio: 'Portfolio Management',
    Campaign: 'Sales Tool'
  }
}
