import { Button } from '@components/Button'
import { theme } from '@utils/theme'
import styled from 'styled-components/macro'
import { Text } from '@components/Text'
import { RatingContainer } from '../Rating/styled'

export const MessageContainer = styled.div<{ sender?: string }>`
  padding-right: 15px;
  min-width: 100px;

  align-self: ${props => (props.sender === 'Assistant' ? 'flex-start' : 'flex-end')};

  &:hover ${RatingContainer} {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.2s;
  }
`

export const CustomerMessageContainer = styled(MessageContainer)`
  display: flex;
  align-self: auto;
  flex-direction: column;
`
export const Disclaimer = styled.div`
  font-size: 0.83em;
  color: grey;
  margin-top: 2%;
  align: center;
`

export const StyledText = styled(Text)`
  fill: ${theme.colors.main};
  color: ${theme.colors.main};
`

export const AssistantMessageContainer = styled(MessageContainer)`
  align-self: flex-start;
  min-width: 100px;
  width: 90%;
  margin-bottom: 30px;
`

const MessageBubble = styled.div`
  display: flex;
  flex-direction: column;
  white-space: normal;
  padding: 10px 14px;
  margin: 3px 0;
  color: ${theme.colors.navy};
  max-width: 90%;
  position: relative;
`

export const AssistantMessageBubble = styled(MessageBubble)`
  background-color: ${theme.colors.botticelli};
  align-self: flex-start;
  border-radius: 0px 0px 25px 0px;

  &:before {
    content: '';
    position: absolute;
    left: 15px;
    bottom: -7px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: ${theme.colors.botticelli} transparent transparent transparent;
  }
  &:not(:first-child):hover + ${RatingContainer} {
    visibility: visible;
  }
`

export const CustomerMessageBubble = styled(MessageBubble)`
  background-color: #e6f7ff;
  align-self: flex-end;
  border-radius: 0px 0px 0px 25px;

  &:before {
    content: '';
    position: absolute;
    right: 15px;
    bottom: -7px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #e6f7ff transparent transparent transparent;
  }
`

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  min-height: 260px;
  max-height: 260px;
`
export const ConfirmationBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #eaeaea;
  margin-bottom: -6px;
`

export const StyledButton = styled(Button)`
  margin: 0 5px;
  flex: 1;
  min-width: 6em;
`
