/* eslint-disable import/no-default-export */
export default {
  BackToLogin: 'Torna al login',
  SimpleUsefulPowerful: 'Semplice, utile e potente.',
  MargoWasSurprise: 'Margò è stata una meravigliosa sorpresa per me e la mia squadra.',
  ContactCrif: 'Contatta {{companyName}} per aprire un account o chiedere informazioni.',
  ContactUsText: 'Contattaci per aprire un account o chiedere informazioni.',
  Name: 'Nome',
  InsertName: 'Inserisci il tuo nome',
  Surname: 'Cognome',
  InsertSurname: 'Inserisci il tuo cognome',
  Email: 'Email',
  InsertEmail: 'Inserisci la tua mail',
  Message: 'Messaggio',
  InsertMessage: 'Scrivi qui il tuo messagio',
  IAgreeWith: 'Accetto la',
  PrivacyPolicy: 'privacy policy',
  Send: 'Invia',
  SuccessITA: 'Il tuo messaggio è stato correttamente inviato al Customer Care.',
  ContactCRIF: 'Contatta {{companyName}}',
  ContactUs: 'Contattaci',
  Company: 'Impresa',
  InsertCompany: 'Inserisci la tua impresa',
  Country: 'Stato',
  InsertCountry: 'Choose your Country',
  JobTitle: 'Titolo di lavoro',
  InsertJobTitle: 'Inserisci il titolo del tuo lavoro',
  Telephone: 'Numero di telefono',
  InsertTelephone: 'Inserisci il tuo numero di telefono',
  CompanyName: 'Denominazione',
  ContactCustomer: 'Contatta il cliente',
  EnterContactDetails: 'Inserisci i dati dei referenti',
  EnterContactDetailsSubtitle: 'Inserisci i dati di contatto del referente che intendi contattare',
  ContactName: 'Nome del referente',
  ContactSurname: 'Cognome del referente',
  ContactEmail: 'Email del referente',
  ContactTelephone: 'Recapito telefonico del referente',
  ConfirmOperation: 'Conferma operazione',
  ConfirmOperationDescription: "L'operazione potrebbe richiedere alcuni minuti. Procedere con l'operazione?",
  CreateOffer: 'Crea offerta',
  ContactCustomerOnline: 'Contatta il cliente online',
  ProceedQuestion: 'Vuoi procedere?',
  ErrorMessage:
    "Attenzione: non puoi creare una nuova offerta su questa impresa, perché ne esiste già una da completare. Completala direttamente sull'applicativo <1>Apparound</1>."
}
