import _ from 'lodash'
import { useQuery, useInfiniteQuery, UseQueryOptions, keepPreviousData } from '@tanstack/react-query'
import { CompoundExpression } from '@api/expressionTypes'
import { SemanticSearchRequest } from '@api/types'
import { AxiosError } from 'axios'
import { apiCallWithCancel } from '../../utils/helpers'
import { PromisedType, CompanyDetailsType, MapPositionData } from '../../types'
import {
  getPortfolio,
  getPortfolioList,
  getPortfolioCompanies,
  comparePortfolioVariables,
  getPortfolioMapCompanies,
  getPortfolios,
  getUsersInPortfolio,
  getPortfolioListForTargeting,
  getUsersExclusionsInPortfolio,
  getBranchTypesList,
  getPortfolioCustomVariables,
  getPortfolioCompanyDetails
} from './api'
import {
  PortfolioSelectListParams,
  PortfolioCompaniesResponse,
  GetPortfoliosRequest,
  PortfolioCompaniesForMap
} from './types'
import { PORTFOLIO_COMPANIES_PAGE_SIZE } from '../../features/portfolio/constants'
import { createPortfolioCompaniesMapReq } from '../../features/portfolioDetailPage/mappers'
import { convertCompoundExpressionForBackend, expressionWithExcludedPortfolio } from '../../features/filters/utils'
import { cacheAllTheTime } from '../../features/queryclient'

type BasePortfolioCompanies = {
  portfolioId: string
  semantic: SemanticSearchRequest | undefined
  excludedPortfoliosIds: string[] | undefined
  filterEnriched: boolean | undefined
  companyPortfolioStatus: string | undefined
}

type withQuery = {
  query: CompoundExpression | undefined
}

type withMap = {
  mapPositionData: MapPositionData | undefined
  mapMaxZoom: number
}

type withExpression = {
  expression: CompoundExpression | undefined
}

type withPageTypeAndsize = {
  pageType: CompanyDetailsType
  size: number
}

type PortfolioCompaniesWithAllDataType = BasePortfolioCompanies & withQuery & withPageTypeAndsize

type InfinitePortfolioCompaniesWithAllDataType = BasePortfolioCompanies & withQuery

type PortfolioMapCompaniesType = BasePortfolioCompanies & withQuery & withMap

type PortfolioCompaniesData = BasePortfolioCompanies & withExpression

export type PortfolioCompaniesAllData = {
  portfolioId: string
  excludedPortfoliosIds: string[] | undefined
  semantic?: SemanticSearchRequest
  filterEnriched?: boolean
  companyPortfolioStatus?: string
} & withExpression &
  withPageTypeAndsize

export const getPortfolioKeys = {
  All: () => [{ level1: 'portfolios' }] as const,
  Options: () => [{ ...getPortfolioKeys.All()[0], level2: 'portfolioOptions' }] as const,
  GetPortfolioList: (params: PortfolioSelectListParams) => [{ ...getPortfolioKeys.Options()[0], params }] as const,
  GetPortfolioListForTargeting: (params: PortfolioSelectListParams) =>
    [{ ...getPortfolioKeys.Options()[0], level2: 'getPortfolioListForTargeting', params }] as const,
  Portfolio: () => [{ ...getPortfolioKeys.All()[0], level2: 'getPortfolio' }] as const,
  PortfolioById: (portfolioId: string) => [{ ...getPortfolioKeys.Portfolio()[0], portfolioId }] as const,
  UsersInPortfolio: (portfolioId: string) =>
    [{ ...getPortfolioKeys.PortfolioById(portfolioId)[0], level3: 'getUsersInPortfolio' }] as const,
  ExclusionsUsersInPortfolio: (portfolioId: string) =>
    [{ ...getPortfolioKeys.PortfolioById(portfolioId)[0], level3: 'getExclusionsUsersInPortfolio' }] as const,
  PortfolioCompanies: () => [{ ...getPortfolioKeys.All()[0], leve2: 'getPortfolioCompanies' }] as const,
  PortfolioCompaniesForPortfolioId: (portfolioId: string) =>
    [{ ...getPortfolioKeys.PortfolioCompanies()[0], portfolioId }] as const,
  PortfolioCompaniesWithAllData: ({ portfolioId, ...rest }: PortfolioCompaniesWithAllDataType) =>
    [
      {
        ...getPortfolioKeys.PortfolioCompaniesForPortfolioId(portfolioId)[0],
        ...rest
      }
    ] as const,
  InfinitePortfolioCompaniesWithAllData: ({ portfolioId, ...rest }: InfinitePortfolioCompaniesWithAllDataType) =>
    [
      {
        ...getPortfolioKeys.PortfolioCompaniesForPortfolioId(portfolioId)[0],
        level3: 'Infinite',
        ...rest
      }
    ] as const,
  ComparePortfolioVariables: (portfolioId: string, targetPortfolioId?: string) =>
    [{ ...getPortfolioKeys.All()[0], level2: 'comparePortfolioVariables', portfolioId, targetPortfolioId }] as const,
  PortfolioMapCompanies: (props: PortfolioMapCompaniesType) =>
    [
      {
        ...getPortfolioKeys.All()[0],
        level2: 'getPortfolioMapCompanies',
        ...props
      }
    ] as const,
  GetPortfolios: () => [{ ...getPortfolioKeys.All()[0], level2: 'getPortfolios' }] as const,
  GetPortfoliosWithData: (props: GetPortfoliosRequest) =>
    [{ ...getPortfolioKeys.GetPortfolios()[0], ...props }] as const,
  GetBranchTypes: () => [{ ...getPortfolioKeys.All()[0], level2: 'getBranchTypes' }] as const,
  PortfolioCustomVariables: (portfolioId?: string) =>
    [{ ...getPortfolioKeys.All()[0], level2: 'getPortfolioCustomVariables', portfolioId }] as const,
  PortfolioCompanyDetails: (margoId: string) => [{ ...getPortfolioKeys.All()[0], margoId }] as const
}

export const useGetPortfolioList = (
  params: PortfolioSelectListParams,
  options?: UseQueryOptions<
    PromisedType<typeof getPortfolioList>,
    unknown,
    PromisedType<typeof getPortfolioList>,
    ReturnType<typeof getPortfolioKeys.GetPortfolioList>
  >
) =>
  useQuery({
    queryKey: getPortfolioKeys.GetPortfolioList(params),
    queryFn: ({ queryKey: [queryKeys] }) => getPortfolioList(queryKeys.params),
    ...options
  })

export const useGetPortfolioListForTargeting = (
  params: PortfolioSelectListParams,
  options?: UseQueryOptions<
    PromisedType<typeof getPortfolioListForTargeting>,
    unknown,
    PromisedType<typeof getPortfolioListForTargeting>,
    ReturnType<typeof getPortfolioKeys.GetPortfolioListForTargeting>
  >
) => {
  return useQuery({
    queryKey: getPortfolioKeys.GetPortfolioListForTargeting(params),
    queryFn: () => {
      return getPortfolioListForTargeting(params)
    },
    ...options
  })
}

export const useGetPortfolio = (portfolioId: string) =>
  useQuery({
    queryKey: getPortfolioKeys.PortfolioById(portfolioId),
    queryFn: ({ queryKey: [queryKeys] }) => getPortfolio(queryKeys.portfolioId).then(resp => resp.data),
    ...cacheAllTheTime
  })

export const useGetUsersInPortfolio = (portfolioId: string) =>
  useQuery({
    queryKey: getPortfolioKeys.UsersInPortfolio(portfolioId),
    queryFn: () => getUsersInPortfolio(portfolioId).then(resp => resp.data)
  })

export const useGetExclusionsUsersInPortfolio = (portfolioId: string) =>
  useQuery({
    queryKey: getPortfolioKeys.ExclusionsUsersInPortfolio(portfolioId),
    queryFn: () => getUsersExclusionsInPortfolio(portfolioId).then(resp => resp.data)
  })

export const useGetPortfolioCompanies = ({
  portfolioId,
  expression,
  pageType,
  size = PORTFOLIO_COMPANIES_PAGE_SIZE,
  excludedPortfoliosIds,
  semantic,
  filterEnriched,
  companyPortfolioStatus
}: PortfolioCompaniesAllData) => {
  const query = expression
    ? expressionWithExcludedPortfolio(convertCompoundExpressionForBackend(expression), excludedPortfoliosIds)
    : expression

  return useQuery({
    queryKey: getPortfolioKeys.PortfolioCompaniesWithAllData({
      portfolioId,
      query,
      pageType,
      size,
      excludedPortfoliosIds: undefined,
      semantic,
      filterEnriched,
      companyPortfolioStatus
    }),
    queryFn: ({ queryKey: [queryKeys] }) =>
      getPortfolioCompanies(queryKeys.portfolioId, {
        query: queryKeys.query,
        semantic: queryKeys.semantic,
        pageType: queryKeys.pageType,
        pagination: { position: 0, size: queryKeys.size },
        isEnrichedQuery: queryKeys.filterEnriched,
        companyPortfolioStatus: queryKeys.companyPortfolioStatus
      }).then(response => response.data),
    ...cacheAllTheTime
  })
}

export const getPortfolioCompanyNextPage = (
  lastPage: PortfolioCompaniesResponse | undefined,
  allPages: PortfolioCompaniesResponse[]
): number | undefined => {
  const total = lastPage?.data.total || 0

  const count = _.sum(_.map(allPages, 'data.items.length'))
  return total <= count ? undefined : allPages.length
}

export const useInfiniteGetPortfolioCompanies = ({
  portfolioId,
  expression,
  semantic,
  excludedPortfoliosIds,
  filterEnriched,
  companyPortfolioStatus
}: PortfolioCompaniesData) => {
  const query = expression
    ? expressionWithExcludedPortfolio(convertCompoundExpressionForBackend(expression), excludedPortfoliosIds)
    : expression

  return useInfiniteQuery({
    queryKey: getPortfolioKeys.InfinitePortfolioCompaniesWithAllData({
      portfolioId,
      query,
      semantic,
      excludedPortfoliosIds: undefined,
      filterEnriched,
      companyPortfolioStatus
    }),
    queryFn: ({ queryKey: [queryKeys], pageParam }) =>
      getPortfolioCompanies(queryKeys.portfolioId, {
        query: queryKeys.query,
        semantic: queryKeys.semantic,
        pageType: CompanyDetailsType.FullDetails,
        pagination: { position: pageParam, size: PORTFOLIO_COMPANIES_PAGE_SIZE },
        isEnrichedQuery: queryKeys.filterEnriched,
        companyPortfolioStatus: queryKeys.companyPortfolioStatus,
        updateLastViewedDate: true
      }).then(response => response.data),
    initialPageParam: 0,
    getNextPageParam: getPortfolioCompanyNextPage.bind(null),
    enabled: !!portfolioId
  })
}

export const useComparePortfolioVariables = (portfolioId: string, targetPortfolioId?: string) =>
  useQuery({
    queryKey: getPortfolioKeys.ComparePortfolioVariables(portfolioId, targetPortfolioId),
    queryFn: async ({ queryKey: [queryKeys] }) => {
      try {
        if (!queryKeys.targetPortfolioId) return false
        await comparePortfolioVariables({
          sourcePortfolioId: queryKeys.portfolioId,
          targetPortfolioId: queryKeys.targetPortfolioId
        })
        return false
      } catch (e) {
        return true
      }
    }
  })

export const useGetPortfolioMapCompanies = ({
  portfolioId,
  mapPositionData,
  query,
  semantic,
  mapMaxZoom,
  filterEnriched,
  companyPortfolioStatus,
  excludedPortfoliosIds
}: PortfolioMapCompaniesType) => {
  const convertedQuery = query ? convertCompoundExpressionForBackend(query) : query
  return useQuery<
    PortfolioCompaniesForMap | undefined,
    AxiosError,
    PortfolioCompaniesForMap,
    ReturnType<typeof getPortfolioKeys.PortfolioMapCompanies>
  >({
    queryKey: getPortfolioKeys.PortfolioMapCompanies({
      portfolioId,
      mapPositionData,
      query: convertedQuery,
      semantic,
      mapMaxZoom,
      filterEnriched,
      companyPortfolioStatus,
      excludedPortfoliosIds
    }),
    queryFn: ({ queryKey: [queryKeys] }) =>
      apiCallWithCancel(token => {
        if (!queryKeys.mapPositionData) return (async () => undefined)()
        return getPortfolioMapCompanies(
          queryKeys.portfolioId,
          createPortfolioCompaniesMapReq({
            pos: queryKeys.mapPositionData,
            query: queryKeys.query,
            semantic: queryKeys.semantic,
            maxZoom: queryKeys.mapMaxZoom,
            isEnrichedQuery: queryKeys.filterEnriched,
            companyPortfolioStatus: queryKeys.companyPortfolioStatus,
            excludedPortfoliosIds: queryKeys.excludedPortfoliosIds
          }),
          token
        ).then(res => res.data)
      }),
    placeholderData: keepPreviousData
  })
}

export const useGetPortfolios = (props: GetPortfoliosRequest) =>
  useInfiniteQuery({
    queryKey: getPortfolioKeys.GetPortfoliosWithData(props),
    queryFn: ({ queryKey: [queryKeys], pageParam }) =>
      getPortfolios({
        top: queryKeys.top,
        skip: pageParam,
        nameLike: queryKeys.nameLike,
        shareType: queryKeys.shareType
      }).then(res => res.data),
    initialPageParam: 0,
    getNextPageParam: (lastResp, allPages) => {
      const count = _.sum(_.map(allPages, 'portfolios.length'))
      return lastResp?.totalCount <= count ? undefined : count
    }
  })

export const useGetBranchTypes = () =>
  useQuery({ queryKey: getPortfolioKeys.GetBranchTypes(), queryFn: getBranchTypesList })

export const useGetPortfolioCustomVariablesTypes = (portfolioId?: string) =>
  useQuery({
    queryKey: getPortfolioKeys.PortfolioCustomVariables(portfolioId),
    queryFn: ({ queryKey: [queryKeys] }) => {
      if (queryKeys.portfolioId === undefined) return null
      return getPortfolioCustomVariables(queryKeys.portfolioId)
    },
    select: data => _.map(data?.customVars, ({ type }) => type)
  })

export const useGetPortfolioCompanyDetails = (margoId: string) =>
  useQuery({
    queryKey: getPortfolioKeys.PortfolioCompanyDetails(margoId),
    queryFn: ({ queryKey: [queryKeys] }) => getPortfolioCompanyDetails(queryKeys.margoId),
    enabled: !!margoId
  })
