/* eslint-disable camelcase */
/* eslint-disable import/no-default-export */
export default {
  Title: 'Váš účet',
  Navigation: {
    Account: 'Účet',
    SavedReport: 'Uložený report',
    PortfolioManagement: 'Správa portfolia',
    Targeting: 'Analýza trhu',
    Prospecting: 'Výběr klientů',
    Operations: 'Operace',
    SalesTool: 'Nástroj pro prodej',
    Products: 'Produkty',
    GoalsTasks: 'Cíle a úkoly',
    ReleaseNotes: 'Poslední zprávy',
    Configuration: 'Konfigurace',
    Appointments: 'Schůzky'
  },
  Goal: {
    AddGoal: 'Přidat cíl',
    AddGoalTooltip: 'Vytvořte si cíl, který si vyberete v komerčních kampaních',
    EditGoal: {
      Title: 'Upravit cíl',
      Body: 'Zadejte reprezentativní název cíle',
      NameInput: 'Název cíle',
      Success: 'Cíl úspěšně změněn.',
      Error: 'Při úpravě cíle došlo k chybě.'
    },
    DeleteGoal: {
      Title: 'Smazat cíl',
      Subtitle: 'Jste si jistí, že to chcete smazat {{goalName}}?',
      Body: 'Akce bude provedena pouze v případě, že cíl nebyl přiřazen žádné aktivní kampani; jinak bude žaloba zamítnuta.',
      Success: 'Cieľ bol úspešne odstránený.',
      Error: 'Při mazání cíle došlo k chybě.',
      ErrorAssignedToActiveCampaign: 'Vybraný cíl není možné smazat, protože je přiřazen k aktivní kampani.',
      ErrorActiveActionsForSameOrganization:
        'Operaci nebylo možné dokončit kvůli jiným nevyřízeným akcím. Zkuste to prosím znovu za několik minut.'
    },
    EditTask: {
      Title: 'Změňte název úkolu',
      Body: 'Zadejte reprezentativní název úkolu',
      NameInput: 'Název úkolu',
      Success: 'Úkol byl úspěšně upraven',
      Error: 'Při úpravě úkolu došlo k chybě.'
    },
    DeleteTask: {
      Title: 'Smazat úkol',
      Subtitle: 'Jste si jistí, že chcete smazat {{taskName}}?',
      Body: 'Akce bude provedena pouze v případě, že zmíněný úkol nebyl přiřazen k žádné aktivní kampani; jinak bude akce zamítnuta.',
      Success: 'Úkol byl úspěšně smazán',
      Error: 'Při mazání úkolu došlo k chybě.',
      ErrorAssignedToActiveCampaign:
        'Vybraný úkol nelze smazat, protože v aktivních kampaních jsou společnosti, kterým byl zmíněný úkol přiřazen. Chcete-li pokračovat v mazání, odstraňte přiřazení v kampani a zkuste úkol odstranit znovu.',
      ErrorActiveActionsForSameOrganization:
        'Operaci nebylo možné dokončit kvůli jiným nevyřízeným akcím. Zkuste to prosím znovu za několik minut.'
    },
    CreateGoal: 'Vytvořit cíl',
    NameDesc: 'Zadejte reprezentativní název cíle, který vytváříte.',
    GoalNamePlaceholder: 'Uveďte název cíle',
    GoalNameLabel: 'Název cíle',
    TasksHeader: 'Vyberte si úkoly k dosažení svého cíle',
    TaskDesc:
      'Zadejte všechny úkoly potřebné k dosažení tohoto cíle (min. 3, max. 10). Nižší čísla označují nízkou míru dokončení cíle.',
    TaskPlaceholder: 'Zadejte název úkolu',
    TaskNumber: 'Číslo úkolu',
    LastTaskDesc: '* Tento úkol se bude považovat za konečný úkol kampaně',
    AddTask: 'Přidat úkol',
    Number: 'Číslo',
    Task: 'Úkol',
    Desc1: 'Číslo blízko úkolu označuje úroveň dokončení cíle.',
    Desc2: 'Nižší čísla označují nízkou míru dokončení cíle.',
    Table: {
      Name: 'Jméno',
      NumberOfTasks: 'Počet úkolů'
    },
    EmptyText: 'Zde najdete všechny cíle a úkoly uložené v sekci "Cíle a úkoly".',
    Visualize: 'Vizualizujte {{goal}}'
  },
  Queries: {
    Table: {
      Name: 'Uložený dotaz',
      CreatedAt: 'Datum vytvoření',
      LastUseDate: 'Datum posledního použití',
      Monitored: 'Sledované',
      ResumeQuery: 'Obnovit dotaz',
      Delete: 'Vymazat',
      ResumePortfolioQueryTooltip: 'Otevřete "Správu portfolia" s přednastavenými filtry z {{queryName}}',
      ResumeProspectingQueryTooltip: 'Otevřete "Výběr klientů" s přednastavenými filtry z {{queryName}}',
      ResumeTargetingQueryTooltip: 'Otevřete "Analýzu trhu" s přednastavenými filtry z {{queryName}}'
    },
    EmptyProspectingText: 'Zde najdete všechny dotazy uložené v sekci "Výběr klientů"',
    EmptyTargetingText: 'Zde najdete všechny dotazy uložené v sekci "Analýza trhu"',
    DeleteDialog: {
      Title: 'Odstranit uložený dotaz?',
      Title_0: 'Odstranit uložený dotaz?',
      Title_1: 'Odstranit uložené dotazy?',
      Title_2: 'Odstranit uložené dotazy?',
      Subtitle: 'Potvrďte pokračování v operaci',
      Body: 'Chcete smazat uložený dotaz?',
      Body_0: 'Chcete smazat uložený dotaz?',
      Body_1: 'Chcete smazat uložené dotazy?',
      Body_2: 'Chcete smazat uložené dotazy?',
      Monitored: 'Pokud je dotaz monitorován, příslušné portfolio se již nebude aktualizovat',
      Success_0: 'Dotaz byl úspěšně vymazán.',
      Success_1: 'Dotazy byly úspěšně vymazány.',
      Success_2: 'Dotazy byly úspěšně vymazány.',
      Error_0: 'Při vymazání dotazu došlo k chybě.',
      Error_1: 'Při vymazání dokumentů došlo k chybě.',
      Error_2: 'Při vymazání dokumentů došlo k chybě.',
      MonitoredQueryError:
        'Některé z vybraných dotazů nelze odstranit, protože se jedná o sledované dotazy. Chcete-li tyto dotazy odstranit, nejprve smažte související monitorovaná portfolia.'
    },
    ResumePortfolioQueryDialog: {
      Title: 'Vyberte portfolio, na které chcete dotaz použít',
      Text: 'Vyberte si jedno z dostupných portfolií.'
    },
    Actions: {
      EmptyDeleteSelectionTitle: 'Vymazat dotazy',
      EmptySelectionText: 'Musíte zvolit alespoň jeden dotaz',
      Delete: 'Vymazat'
    }
  },
  Products: {
    Table: {
      Name: 'Produkt',
      CreatedAt: 'Datum vložení'
    },
    AddProduct: 'Přidat produkt',
    AddProductTooltip: 'Vytvořte produkt, který přiřadíte v komerčních kampaních',
    EditProduct: {
      Title: 'Změňte název',
      Body: 'Zadejte reprezentativní název produktu',
      NameInputLabel: 'Jméno výrobku',
      NameInputPlaceholder: 'Jméno výrobku',
      Success: 'Produkt byl úspěšně upraven.',
      Error: 'Při úpravě produktu došlo k chybě.'
    },
    DeleteProduct: {
      Title: 'Smazat produkt',
      Subtitle: 'Jste si jistí, že to chcete smazat {{productName}}?',
      Body: 'Akce bude provedena pouze v případě, že zmíněný produkt nebyl přiřazen k žádné aktivní kampani; jinak bude akce zamítnuta.',
      Success: 'Produkt byl úspěšně smazán',
      Error: 'Při mazání produktu došlo k chybě.',
      ErrorAssignedToActiveCampaign:
        'Vybraný produkt není možné smazat, protože existuje aktivní kampaň/portfolio, které je přiřazeno k uvedenému produktu.',
      ErrorActiveActionsForSameOrganization:
        'Operaci nebylo možné dokončit kvůli jiným nevyřízeným akcím. Zkuste to prosím znovu za několik minut.'
    },
    EmptyText: 'Zde naleznete všechny produkty uložené v sekci "Produkty"',
    CreateProduct: 'Vytvořit produkt',
    NameDesc: 'Zadejte reprezentativní název produktu, který vytváříte.',
    ProductNamePlaceholder: 'Zadejte název produktu',
    ProductNameLabel: 'Název produktu'
  },
  PortfolioManagement: {
    EmptyText: 'Zde najdete všechny dokumenty uložené v sekci "Správa portfolia"',
    Documents: 'Dokumenty',
    DocumentsTable: {
      Name: 'Dokument',
      CreatedAt: 'Datum uložení',
      MimeType: 'Přípona'
    },
    Queries: 'Dotazy',
    DeleteDialog: {
      Body_0: 'Chcete vymazat dokument?',
      Body_1: 'Chcete vymazat dokumenty?',
      Body_2: 'Chcete vymazat dokumenty?',
      Title_0: 'Vymazat dokument?',
      Title_1: 'Vymazat dokumenty?',
      Title_2: 'Vymazat dokumenty?',
      Subtitle: 'Potvrďte pokračování v operaci',
      Success_0: 'Dokument byl úspěšně vymazán.',
      Success_1: 'Dokumenty byly úspěšně vymazány.',
      Success_2: 'Dokumenty byly úspěšně vymazány.',
      Error_0: 'Při vymazání dokumentu došlo k chybě.',
      Error_1: 'Při vymazání dokumentů došlo k chybě.',
      Error_2: 'Při vymazání dokumentů došlo k chybě.'
    },
    Actions: {
      EmptySelectionText: 'Musíte vybrat alespoň jeden dokument',
      EmptyDeleteSelectionTitle: 'Vymazat dokumenty',
      Delete: 'Vymazat',
      Download: 'Stáhnout',
      ShareTrack: 'Sdílet stopu'
    },
    CustomTracks: {
      Title: 'Vlastní stopy',
      EditCustomTrack: 'Upravit vlastní stopu',
      EditSuccessMessage: 'Vlastní stopa byla úspěšně upravena.',
      EditErrorMessage: 'Při úpravě vlastní stopy došlo k chybě.',
      VisualiseCustomTrack: 'Vizualizujte vlastní stopu',
      Table: {
        TrackName: 'Název stopy',
        SaveDate: 'Datum uložení',
        LastUseDate: 'Datum posledního použití',
        OwnerFullName: 'Jméno autora',
        ModuleOfOrigin: 'Forma původu'
      },
      DeleteDialog: {
        Body_0: 'Chcete smazat vlastní stopu?',
        Body_1: 'Chcete smazat vlastní stopy?',
        Body_2: 'Chcete smazat vlastní stopy?',
        Title_0: 'Smazat vlastní stopu',
        Title_1: 'Smazat vlastní stopy',
        Title_2: 'Smazat vlastní stopy',
        Subtitle: 'Potvrďte a pokračujte v operaci.',
        Success_0: 'Vlastní stopa byla úspěšně smazána.',
        Success_1: 'Vlastní stopy byly úspěšně smazány.',
        Success_2: 'Vlastní stopy byly úspěšně smazány.',
        Error_0: 'Při mazání vaší vlastní stopy došlo k chybě.',
        Error_1: 'Při mazání vašich vlastních stop došlo k chybě.',
        Error_2: 'Při mazání vašich vlastních stop došlo k chybě.'
      },
      RenameDialog: {
        Title: 'Změnit název vlastní stopy',
        Body: 'Zadejte reprezentativní název pro vlastní stopu',
        NameInput: 'Vlastní název stopy',
        Success: 'Vlastní stopa byla úspěšně přejmenována.',
        Error: 'Při přejmenování vlastní stopy došlo k chybě.'
      },
      EmptyDialog: {
        Title: 'Smazat vlastní stopy',
        Text: 'Musíte vybrat alespoň jednu vlastní stopu.'
      },
      ShareCustomTrackErrorDialog: {
        Title: 'Sdílení vlastní stopy',
        Text: 'Při sdílení vlastní stopy došlo k chybě.'
      }
    }
  },
  Operations: {
    EmptyText: 'Zde najdete všechny dotazy uložené v sekci "Operace"',
    Table: {
      ResumePortfolioQueryTooltip: 'Otevřete "Operace" s přednastavenými filtry z {{queryName}}'
    }
  },
  Info: {
    User: 'Uživatel',
    LoginAs: 'Přihlásit se jako jiný uživatel',
    Level: 'Úroveň',
    Office: 'Pobočka',
    Subscription: 'Předplatné',
    NameAndSurname: 'Jméno a příjmení',
    JobTitle: 'Pracovní pozice',
    Telephone: 'Telefon',
    CompanyName: 'Název společnosti',
    Country: 'Země'
  },
  Account: {
    Password: {
      Password: 'Heslo',
      Current: 'Stávající',
      ChangePasswordTitle: 'Změnit heslo',
      ChangePassword: 'Změnit heslo',
      EnterPassword: 'Zadat nové heslo',
      OldPassword: 'Staré heslo',
      NewPassword: 'Nové heslo',
      ConfirmPassword: 'Potvrdit heslo'
    },
    Language: {
      Language: 'Jazyk',
      Current: 'Stávající',
      ChangeLanguageTitle: 'Změnit jazyk',
      ChangeLanguage: 'Změnit jazyk obsahu'
    },
    Currency: {
      Title: 'Měna',
      Current: 'Stávající',
      ChangeCurrencyTitle: 'Změnit měnu',
      ChangeCurrency: 'Změnit měnu obsahu'
    },
    Subscription: {
      Title: 'Moje předplatné (jednotky)',
      Available: 'Dostupné',
      AvailableTooltip: 'Zbylý kredit (jednotky) z předplatného dostupného pro váš účet',
      Used: 'Použité',
      UsedTooltip: 'Kredit (jednotky) spotřebovaný na vašem účtu',
      Total: 'Celkové',
      TotalTooltip: 'Kredit (jednotky) zakoupený na váš účet',
      Validity: 'Platnost',
      ValidityInfo: 'Od {{from}} do {{to}}',
      CreditInfoWithoutCompanyName: ' a zažádejte o aktualizaci předplatného nebo si zakupte další kredit',
      Error: 'Limity spotřeby se nepodařilo načíst.'
    },
    Contact: {
      Title: 'Kontakty',
      User: 'Uživatel',
      Email: 'E-mail',
      UpdateInfoWithoutCompanyName: ' a zažádejte o změnu vašich kontaktů'
    }
  },
  TargetingTab: {
    SavedQuery: 'Uložený dotaz',
    DownloadedDocuments: 'Stažené dokumenty'
  },
  QueryDetail: {
    VisualiseQuery: 'Vizualizovat dotaz',
    Monitored: 'Sledované'
  },
  ReleaseNotes: {
    Title: 'Poslední zprávy',
    NewNote: 'Nová poznámka k vydání',
    NoteTitle: 'Titul',
    NoteDescription: 'Stručný popis',
    NoteContent: 'Obsah',
    WhatsNew: 'Co je nového',
    GoToNewsPage: 'Přejděte na stránku s novinkami a podívejte se, co je nového tento měsíc'
  },
  Configuration: {
    ConfigureMargoTitle: 'Konfigurovat Margo',
    CreateOfficeConfiguration: 'Vytvořte konfiguraci kanceláře',
    CreateUserConfiguration: 'Vytvořte uživatelskou konfiguraci',
    SearchOfficePlaceholder: 'Vyhledejte název kanceláře',
    SearchUserPlaceholder: 'Vyhledejte uživatelské jméno',
    EmptyText: 'Zde najdete uloženou konfiguraci.',
    ViewConfigurationTitle: 'Konfigurace pro',
    AddConfiguration: {
      OfficeTitle: 'Vyberte jednu nebo více kanceláří',
      OfficeDescription:
        'Vyberte jednu nebo více kanceláří, pro které chcete vytvořit konfiguraci.\nVytváříte konfiguraci kanceláře, která se použije pro všechny současné i budoucí uživatele. Konfiguraci můžete kdykoli změnit.',
      UsersTitle: 'Vyberte jednoho nebo více uživatelů',
      UsersDescription:
        'Vyberte jednoho nebo více uživatelů, pro které chcete vytvořit konfiguraci.\nPokud uživatel patří do nakonfigurované kanceláře, konfigurace uživatele přepíše tu kancelářskou.',
      ParametersTitle: 'Vyberte parametry',
      ParametersDescription:
        'Vyberte parametry, podle kterých budou firmy filtrovány.\nPokud jste vybrali uživatele, který patří do nakonfigurované kanceláře, níže uvedený seznam představuje použitý filtr kanceláře.\nKonfiguraci kanceláře můžete přepsat pouze pro vybrané uživatele, upravit nebo odstranit všechny filtry.',
      FullMargoLabel: 'Vypněte všechny filtry a zobrazte plnou Margo',
      ConfigurationSuccessful: 'Konfigurace byla úspěšně použita',
      ConfigurationSuccessfulDescription: 'Konfiguraci můžete kdykoli změnit ve svém účtu'
    },
    DeleteConfiguration: {
      Title: 'Smazat konfiguraci',
      Subtitle: 'Opravdu chcete smazat konfiguraci?',
      Body: 'Viditelnost uživatele již nebude omezena na parametry zvolené během konfigurace.',
      Success: 'Konfigurace smazána',
      Error: 'There was error while deleting configuration.'
    },
    Table: {
      UserName: 'uživatelské jméno',
      OfficeName: 'Název kanceláře',
      Creator: 'Tvůrce konfigurace',
      UpdatedAt: 'Datum poslední aktualizace'
    },
    Offices: 'Kanceláře',
    Users: 'Uživatelů',
    UserTabTitle: 'Uživatel',
    OfficeTabTitle: 'Kancelář',
    AppliedFilterTabTitle: 'Použité filtry',
    AlreadyConfigured: 'Již nakonfigurováno',
    FullMargoLabelForView: 'Není použit žádný filtr: uživatel uvidí celé Margo'
  }
}
