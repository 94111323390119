/* eslint-disable camelcase */
/* eslint-disable import/no-default-export */
export default {
  Back: 'Späť',
  Continue: 'Pokračovať',
  Search: 'Hľadať',
  Cancel: 'Zrušiť',
  Ok: 'Ok',
  Save: 'Uložiť',
  SaveAndShare: 'Uložiť a zdieľať',
  Delete: 'Vymazať',
  Move: 'Presunúť',
  Edit: 'Upraviť',
  Rename: 'Premenovať',
  Add: 'Pridať',
  Note: 'Poznámka',
  Select: 'Vybrať',
  Hide: 'Skryť',
  Show: 'Zobraziť',
  Visualise: 'Vizualizovať',
  HideDetail: 'Skryť detail',
  ShowDetail: 'Zobraziť detail',
  Duplicate: 'Duplikovať',
  Download: 'Stiahnuť',
  Collapse: 'Redukovať',
  Loading: 'Načítava sa',
  // COMPANY STATUS
  Prospect: 'Prospekt',
  Lead: 'Lead',
  Client: 'Klient',
  Supplier: 'Dodávateľ',
  Competitor: 'Konkurent',
  RangeSelected: 'Vybraná oblasť',
  Close: 'Zavrieť',
  ViewDetails: 'Zobraziť podrobnosti',
  SearchResults: {
    ResultsFor: 'Výsledky pre',
    ExcludeHiddenPortfolio: ' s výnimkou spoločností patriacich do skrytých portfólií.',
    SendToSemanticSearch: 'Odoslať do sémantického vyhľadávania',
    SendToSemanticSearchToolTip: 'Vyhľadajte kľúčové slovo v sémantickom vyhľadávaní'
  },
  NumericRange: {
    InvalidValue: 'Neplatná hodnota.',
    MinGreaterThanMax: 'Min. hodnota je väčšia ako max.',
    MaxLessThanMin: 'Max. hodnota je menšia ako min.',
    MinOutOfrange: 'Minimálna hodnota je mimo rozsahu.',
    MaxOutOfrange: 'Maximálna hodnota je mimo rozsahu.'
  },
  DateRange: {
    From: 'Od',
    To: 'Do'
  },
  DomainFilter: {
    SaveAndClose: 'Uložiť filtre a zavrieť',
    SeeAll: 'Všetky {{count}} filtre',
    DeleteAll: 'Vymazať všetko',
    Multiselection: 'Multi-výber'
  },
  Reset: 'Resetovať',
  SelectAll: 'Vybrať všetko',
  DeselectAll: 'Odznačiť všetko',
  DoYouWantToContinue: 'Chcete pokračovať?',
  NotSwitch: 'Not',
  Yes: 'Áno',
  No: 'Nie',
  On: 'Zap',
  Off: 'Vyp',
  LoadMore: 'Načítať viac',
  SeeAll: 'Vidieť všetko',
  HideAll: 'Skryť',
  Filters: {
    UnlockFilters: 'Chcete spresniť vyhľadávanie? <0>Kliknutím sem</0> odomknite viac ako 300 Margo filtrov',
    NoFilters: 'Nevybrali ste žiadne filtre!',
    Select: 'Vybrať filtre',
    Filters: 'Filtre',
    EditFilters: 'Upraviť filtre k aktualizácii zoznamu',
    StartNewSearch: 'Začať nové vyhľadávanie',
    ResetFilters: 'Resetuj všetky filtre',
    UploadList: 'Nahraj zoznam',
    UploadListTooltip: 'Vložte jednu alebo viac hodnôt oddelených medzerou.'
  },
  MapFilter: {
    FindMe: 'Nájdi ma na mape',
    Address: 'Adresa',
    Range: 'Rozsah',
    SelectedCircle: '{{distance}} od {{address}}',
    SelectedPolygon: 'Oblasť vybraná na mape',
    CompanyName: 'Názov spoločnosti'
  },
  InsertTheNumber: 'Vložte číslo',
  OrderBy: 'Zoradiť podľa',
  BackToLogin: 'Späť k prihláseniu',
  SimpleUsefulPowerful: 'Jednoduché, užitočné a výkonné.',
  MargoWasSurprise: 'Margo bolo pre mňa a môj tím úžasným prekvapením.',
  Targeting: 'Analýza trhu',
  Prospecting: 'Výber klientov',
  PortfolioManagement: 'Správa portfólia',
  Actions: 'Akcie',
  GroupActions: {
    GroupActions: 'Skupinová akcia',
    Actions: 'Akcie',
    Select: 'Výber',
    ToggleTooltip:
      'Prepínačom skupinových akcií vyberte požadované spoločnosti začiarknutím alebo urobte viacnásobný výber. Vybrané spoločnosti potom môžete uložiť z ponuky Akcie. Nebudete môcť vybrať žiadnu výzvu na akciu.',
    ButtonDisabledByGroupActions:
      'Aktiváciou skupinových akcií nebudete môcť vybrať žiadnu výzvu na akciu. Ak chcete použiť tieto tlačidlá, vypnite prepínač Skupinové akcie.',
    DeactivateToggleTooltip:
      'Deaktivujte skupinové akcie, aby ste mohli používať tlačidlá výzvy na akciu v hornej časti stránky.'
  },
  Confirm: 'Potvrdiť',
  IsEnriched: 'Obohatená',
  Logout: 'Odhlásiť',
  ChangeCountry: 'Zmeniť krajinu',
  TrialUser: 'Skúšobný účet',
  You: 'Vy',
  Legend: 'Legenda',
  Enrich: {
    Enrichment: 'Obohatenie',
    RecapDesc:
      'Obohatením spoločnosti o údaje spoločnosti {{companyName}} sa tieto informácie stiahnu a budú sa pravidelne aktualizovať.',
    CompaniesToBeEnriched_0: 'Spoločnosť k obohateniu',
    CompaniesToBeEnriched_1: 'Spoločnosti k obohateniu',
    CompaniesToBeEnriched_2: 'Spoločností k obohateniu',
    PortfolioInfo1: 'Táto spoločnosť nie je prítomná v sekcii Správa portfólia. Pridajte si ju do portfólia.',
    PortfolioInfo2: 'Ukladáte údaje spoločnosti {{companyName}}, spoločnosť už bude obohatená.',
    SelectPortfolio: 'Vybrať portfólio',
    UploadedPortfolio: 'Nahrané portfólio',
    CreateNewPortfolio: 'Vytvoriť nové portfólio',
    PortfolioName: 'Názov portfólia',
    EnrichmentComplete: 'Obohatenie hotové'
  },
  ConfigGuardErrorPage: {
    Reload: 'Znovu načítať',
    Subtitle: 'Aplikačný config sa nepodarilo načítať.',
    Title: 'Je nám ľúto'
  },
  Uploaded: 'Nahraných',
  Date: 'Dátum',
  Page: 'Strana',
  Validating: 'Validácia',
  AutoLogout: {
    Title: 'Automatické odhlásenie',
    Text_0: 'Z dôvodu vašej neaktivity budete automaticky odhlásený za {{count}} sekundu',
    Text_1: 'Z dôvodu vašej neaktivity budete automaticky odhlásení za {{count}} sekundy',
    Text_2: 'Z dôvodu vašej neaktivity budete automaticky odhlásení za {{count}} sekúnd'
  },
  ForceLogout: {
    Title: 'Užívateľ odhlásený',
    Desc: 'Váš užívateľ bol odhlásený, pretože aktuálna relácia bola ukončená alebo bola iniciovaná nová relácia prostredníctvom iného prehliadača alebo zariadenia.'
  },
  PrivacyPolicy: 'Zásady ochrany osobných údajov',
  CookiesPolicy: 'Pravidlá používania cookies',
  TermsOfUse: 'Podmienky použitia',
  SelectDate: 'Vybrať dátum',
  queries: {
    loadQuery: {
      dialogTitle: 'Načítať uložené vyhľadávanie',
      OkBtn: 'Načítať',
      title: 'Vybrať z uložených vyhľadávaní ',
      description: 'Vyberte, v ktorej analýze chcete pokračovať, môžete pokračovať jednou zo svojich uložených analýz.',
      selectTitle: 'Uložené dopyty'
    }
  },
  SaveList: {
    CreateTagTitle: 'Vytvoriť značku',
    CreateTagLabel: 'Vytvoriť značku (voliteľné)',
    CreateTagDesc: 'Každá spoločnosť bude definovaná značkou, ktorú vytvoríte',
    InsertTag: 'Vložiť značku',
    SaveList: 'Uložiť zoznam',
    SaveOrCreate: 'Chcete pridať zoznam do portfólia alebo vytvoriť nové?',
    SelectPortfolio: 'Vybrať portfólio',
    SaveListDesc: 'Zoznam sa automaticky uloží so statusom "Prospekt" v časti "Správa portfólia"',
    UploadedPortfolio: 'Aktualizované portfólio',
    CreateNew: 'Vytvoriť nové',
    SelectNumberOfCompanies:
      'Vyberte počet spoločností, ktoré chcete pridať do svojho portfólia, a zoraďte ich podľa jedného z kritérií v zozname.',
    NumberOfCompanies: 'Počet spoločností',
    BackToFullList: 'Spät do kompletného zoznamu',
    SavingFullList: 'Ukladáte kompletný zoznam spoločností',
    OrderByOptions: {
      Turnover: 'Obrat',
      NOfEmployees: 'Počet zamestnancov',
      TurnoverEur: 'Obrat (EUR)',
      TurnoverCzk: 'Obrat (CZK)',
      EmployeeRange: 'Počet zamestnancov',
      Ebit: 'Ebit'
    },
    SendToSalesInfo: 'Pred zaslaním zoznamu do obchodného nástroja musíte vytvoriť portfólio zo zoznamu',
    Enrichment: {
      Enrichment: 'Obohatenie',
      EnrichmentComplete: 'Obohatenie hotové',
      EnrichmentDescription:
        'Prostredníctvom obohatenia spoločnosti o {{companyName}} dáta, budú tieto informácie stiahnuté a pravidelne aktualizované',
      CompaniesInList_0: 'Spoločnosť v zozname',
      CompaniesInList_1: 'Spoločnosti v zozname',
      CompaniesInList_2: 'Spoločností v zozname',
      CompaniesEnriched_0: 'Obohatená spoločnosť',
      CompaniesEnriched_1: 'Obohatené spoločnosti',
      CompaniesEnriched_2: 'Obohatených spoločností',
      CompaniesNotEnriched_0: 'Neobohatená spoločnosť',
      CompaniesNotEnriched_1: 'Neobohatené spoločnosti',
      CompaniesNotEnriched_2: 'Neobohatených spoločností',
      CreditsWillBeUsed_0: 'Použitá jednotka',
      CreditsWillBeUsed_1: 'Použité jednotky',
      CreditsWillBeUsed_2: 'Použitých jednotiek',
      CreditsRemaining_0: 'Zostávajúca jednotka',
      CreditsRemaining_1: 'Zostávajúce jednotky',
      CreditsRemaining_2: 'Zostávajúcich jednotiek'
    },
    SaveListMonitorDesc:
      'V prípade monitorovaného portfólia môžete nájsť dopyt uložený v sekcii "Výber klientov" vo vašom účte.',
    Tag: 'Značka',
    AllowedCompaniesCountExceeded: 'Zoznam nemôže obsahovať viac ako 100 000 spoločností',
    DontShowThisMessageAnymore: 'Túto správu už nezobrazovať'
  },
  ExcludePortfolio: {
    BasedOn: 'Založené na',
    SelectedPortfolios: 'Vybrané portfóliá',
    AllCompanyInCrifIta: 'Všetkých talianskych spoločnostiach',
    AllCompanyInCrifDeu: 'Všetkých nemeckých spoločnostiach',
    AllCompanyInCrifCzsk: 'všetkých dostupných spoločnostiach',
    ExcludePortfolio: 'Vylúčiť portfólio',
    ExcludeCampaign: 'Vylúčiť kampaň',
    ExcludeEnrichedCompanies: 'Vylúčte obohatené spoločnosti',
    ExcludeHiddenPortfolio: 's výnimkou spoločností vykázaných v skrytých portfóliách',
    Configuration: 'konfigurácii nastavenej vo vašom účte',
    AndConfiguration: 'a konfigurácii nastavenej vo vašom účte'
  },
  User: {
    Role: {
      Supervisor: 'Vedúci',
      AreaManager: 'Oblastný manažér',
      Sales: 'Obchodník'
    },
    UserName: 'Užívateľské meno'
  },
  Freemium: {
    NotAvailableFeature: 'Nie je k dispozícii pre Margo Freemium',
    UnlockFeature: '<0>Kliknutím sem</0> kontaktujte náš predajný tím a odomknite všetky funkcie',
    UnlockSection: 'Ak chcete odomknúť túto sekciu, kontaktujte náš zákaznícky servis',
    MaximumNumberOfPortfoliosReached:
      'Dosiahli ste maximálny počet portfólií. <0>Kliknutím sem</0> odomknete ďalšie portfóliá spolu so všetkými ostatnými funkciami kontaktovaním nášho obchodného tímu alebo odstráňte jedno z existujúcich portfólií.'
  },
  FeatureNotEnabled: 'Túto funkciu momentálne nemáte povolenú. Ak ju chcete povoliť, kontaktujte nás.',
  ClickToSort: 'Kliknutím zoradíte',
  Assignee: 'Príjemca',
  SaveQuery: {
    DialogTitle: 'Uložiť vyhľadávanie',
    Description:
      'Zadajte názov, ktorý chcete použiť na uloženie výskumu, alebo vyberte existujúci dopyt, ktorý chcete prepísať.',
    InputLabel: 'Názov dopytu',
    InputPlaceholder: 'Názov vyhľadávania',
    UpdateExisting: 'Aktualizovať existujúce',
    CreateNew: 'Vytvor nový',
    DoNotCreate: 'Nevytvárať',
    AddToDashboardConfirmMessage: 'Chcete pridať dopyt na svoj dashboard?',
    AddToDashboardCheckboxText: 'Zobraziť na dashboarde',
    SavedQuery: 'správne uložené.',
    SavedQueryAndDashboard: 'uložený a pridaný do dashboardu.',
    SavedQueryDescription: 'Dopyt nájdete uložený v úložisku súvisiacom s referenčnou oblasťou.'
  },
  AssistantAi: {
    ChatTitle: 'Asistent Margò',
    Assistant: 'Asistent',
    Customer: 'Ty',
    Writing: 'Píšuci',
    Feedback: 'Ďakujem za vašu spätnú väzbu!',
    Hello: 'Ahoj {{userFirstName}}, ako ti môžem pomôcť?',
    Placeholder: 'Vložte sem vašu správu',
    DeletionConfirmation: 'Ste si istý, že chcete vymazať chat?'
  },
  ShareQuery: {
    Offer: {
      Title: 'Zdieľať dopyt',
      Subtitle: 'Vyberte používateľov',
      Description: 'Vyberte používateľov, s ktorými chcete zdieľať dopyt',
      ShareSuccess: 'Aktualizácia zdieľania úspešne vykonaná',
      ShareError: 'Zlyhanie zdieľania dopytu'
    },
    Confirmation: {
      DialogTitle: 'Uložiť zdieľaný dopyt',
      SelectName: 'Zvoľte názov pre svoj dopyt alebo si ponechajte pôvodný.',
      SharingAcceptedSubtitle: 'Výskum {{queryName}} bol úspešne prijatý a uložený',
      SharingAcceptedText: 'Výskum nájdete uložený v repozitári súvisiacom s referenčným modulom.',
      Accept: 'Uložiť zdieľaný dopyt'
    }
  }
}
