/* eslint-disable camelcase */
/* eslint-disable import/no-default-export */
export default {
  Title: 'Votre compte',
  Navigation: {
    Account: 'Compte',
    SavedReport: 'Rapport enregistré',
    PortfolioManagement: 'Gestion des portefeuilles',
    Targeting: 'Analyser marché',
    Prospecting: 'Prospection',
    Operations: 'Opérations',
    SalesTool: 'Outil de vente',
    Products: 'Produits',
    GoalsTasks: 'Objectifs et Tâches',
    ReleaseNotes: 'Dernières actualités',
    Configuration: 'Configuration',
    Appointments: 'Rendez-vous',
    Contacts: 'Contacts'
  },
  Goal: {
    AddGoal: 'Ajouter objectif',
    AddGoalTooltip: 'Créer un objectif à choisir dans les campagnes commerciales',
    EditGoal: {
      Title: "Modifier le nom de l'objectif",
      Body: "Entrez un nom représentatif pour l'objectif",
      NameInput: "Nom de l'objectif",
      Success: 'Objectif modifié avec succès.',
      Error: "Une erreur s'est produite lors de la modification de l'objectif."
    },
    DeleteGoal: {
      Title: "Supprimer l'objectif",
      Subtitle: 'Êtes-vous sûr de vouloir supprimer {{goalName}}?',
      Body: "L'action ne sera effectuée que si l'objectif mentionné n'a été attribué à aucune campagne active ; sinon, l'action sera refusée.",
      Success: 'Objectif supprimé avec succès.',
      Error: "Une erreur s'est produite lors de la suppression de l'objectif.",
      ErrorAssignedToActiveCampaign:
        "Il n'est pas possible de supprimer l'objectif sélectionné, car il existe une campagne active à laquelle l'objectif mentionné a été attribué.",
      ErrorActiveActionsForSameOrganization:
        "L'opération n'a pas pu être effectuée en raison d'autres actions en attente. Veuillez réessayer dans quelques minutes."
    },
    EditTask: {
      Title: 'Changer le nom de la tâche',
      Body: 'Entrez un nom représentatif pour la tâche',
      NameInput: 'Nom de la tâche',
      Success: 'Tâche modifiée avec succès',
      Error: "Une erreur s'est produite lors de l'édition de la tâche."
    },
    DeleteTask: {
      Title: 'Supprimer la tâche',
      Subtitle: 'Etes-vous sûr que vous voulez supprimer {{taskName}}?',
      Body: "L'action ne sera effectuée que si l'objectif mentionné n'a été attribué à aucune campagne active; sinon, l'action sera refusée.",
      Success: 'Tâche supprimée avec succès',
      Error: "Une erreur s'est produite lors de la suppression de la tâche.",
      ErrorAssignedToActiveCampaign:
        "Il n'est pas possible de supprimer la tâche sélectionnée, car il y a des entreprises dans des campagnes actives qui ont été affectées à la tâche mentionnée. Pour procéder à la suppression, supprimez l'affectation dans la campagne et réessayez de supprimer la tâche.",
      ErrorActiveActionsForSameOrganization:
        "L'opération n'a pas pu être effectuée en raison d'autres actions en attente. Veuillez réessayer dans quelques minutes."
    },
    CreateGoal: 'Créer objectif',
    NameDesc: "Saisir un nom représentatif de l'objectif créé.",
    GoalNamePlaceholder: "Saisir le nom de l'objectif",
    GoalNameLabel: 'Nom objectif',
    TasksHeader: "Choisir les tâches pour atteindre l'objectif",
    TaskDesc:
      "Saisir toutes les tâches requises pour atteindre cet objectif (3 mini, 10 maxi). Les nombres inférieurs indiquent un faible taux d'achèvement de l'objectif.",
    TaskPlaceholder: 'Saisir le nom de la tâche',
    TaskNumber: 'Numéro de tâche',
    LastTaskDesc: '* Cette tâche sera considérée comme la tâche finale de la campagne',
    AddTask: 'Ajouter tâche',
    Number: 'Nombre',
    Task: 'Tâche',
    Desc1: "Le nombre près de la tâche identifie le niveau d'achèvement de l'objectif.",
    Desc2: "Les nombres inférieurs indiquent un faible taux d'achèvement de l'objectif.",
    Table: {
      Name: 'Nom',
      NumberOfTasks: 'N° de tâches'
    },
    EmptyText: 'Ici se trouvent tous les objectifs et tâches enregistrés dans la section « objectif et tâche »',
    Visualize: 'Visualiser {{goal}}'
  },
  Queries: {
    Table: {
      Name: 'Requête enregistrée',
      CreatedAt: 'Enregistrer date',
      LastUseDate: 'Date de la dernière utilisation',
      Monitored: 'Suivie',
      ResumeQuery: 'Reprendre la requête',
      Delete: 'Supprimer',
      ResumePortfolioQueryTooltip:
        'Ouvrir « Gestion des portefeuilles » avec les filtres présélectionnés dans {{queryName}}',
      ResumeProspectingQueryTooltip: 'Ouvrir « Prospection » avec les filtres présélectionnés dans {{queryName}}',
      ResumeTargetingQueryTooltip: 'Ouvrir « Analyser marché » avec les filtres présélectionnés dans {{queryName}}'
    },
    EmptyProspectingText: 'Ici se trouvent toutes les requêtes enregistrées dans la section « Prospection »',
    EmptyTargetingText: 'Ici se trouvent toutes les requêtes enregistrées dans la section « Analyser marché »',
    DeleteDialog: {
      Title: 'Supprimer la requête enregistrée ?',
      Title_plural: 'Supprimer les requêtes enregistrées ?',
      Subtitle: "Confirmer la poursuite de l'opération",
      Body: 'Voulez-vous supprimer la requête enregistrée ?',
      Body_plural: 'Voulez-vous supprimer les requêtes enregistrées ?',
      Monitored: 'Si la requête est surveillée, le portefeuille correspondant ne sera plus actualisé',
      Success: 'Requête supprimée avec succès.',
      Success_plural: 'Requêtes supprimées avec succès.',
      Error: "Une erreur s'est produite pendant la suppression de votre requête.",
      Error_plural: "Une erreur s'est produite pendant la suppression de vos requêtes",
      MonitoredQueryError:
        "Certaines des requêtes sélectionnées ne peuvent pas être supprimées car ce sont des requêtes surveillées. Afin de supprimer ces requêtes, veuillez d'abord supprimer les portefeuilles surveillés associés."
    },
    ResumePortfolioQueryDialog: {
      Title: 'Choisir le portefeuille auquel appliquer la requête',
      Text: 'Choisir un des portefeuilles disponibles.'
    },
    Actions: {
      EmptyDeleteSelectionTitle: 'Supprimer les requêtes',
      EmptySelectionText: 'Sélectionner au moins une requête',
      Delete: 'Supprimer'
    }
  },
  Products: {
    Table: {
      Name: 'Produit',
      CreatedAt: "Date d'insertion"
    },
    AddProduct: 'Ajouter produit',
    AddProductTooltip: 'Créer un produit à affecter dans les campagnes commerciales',
    EditProduct: {
      Title: 'Modifier le nom du produit',
      Body: 'Entrez un nom représentatif pour le produit',
      NameInputLabel: 'Nom du produit',
      NameInputPlaceholder: 'Nom du produit',
      Success: 'Produit modifié avec succès',
      Error: "Une erreur s'est produite lors de la modification du produit"
    },
    DeleteProduct: {
      Title: 'Supprimer le produit',
      Subtitle: 'Êtes-vous sûr de vouloir supprimer {{productName}}?',
      Body: "L'action ne sera effectuée que si le produit mentionné n'a été attribué à aucune campagne active ; sinon, l'action sera refusée.",
      Success: 'Produit été supprimé avec succès.',
      Error: 'Le produit a été supprimé avec succès.',
      ErrorAssignedToActiveCampaign:
        "Il n'est pas possible de supprimer le produit sélectionné, car il y a une campagne active qui a été attribuée au produit mentionné.",
      ErrorActiveActionsForSameOrganization:
        "L'opération n'a pas pu être effectuée en raison d'autres actions en attente. Veuillez réessayer dans quelques minutes."
    },
    EmptyText: 'Ici se trouvent tous les produits enregistrés dans la section « Produits »',
    CreateProduct: 'Créer produit',
    NameDesc: 'Saisir un nom représentatif du produit créé.',
    ProductNamePlaceholder: 'Saisir le nom du produit',
    ProductNameLabel: 'Nom du produit'
  },
  PortfolioManagement: {
    EmptyText: 'Ici se trouvent tous les documents enregistrés dans la section Gestion des portefeuilles',
    Documents: 'Documents',
    DocumentsTable: {
      Name: 'Document',
      CreatedAt: 'Date enregistrée',
      MimeType: 'Extension'
    },
    Queries: 'Requêtes',
    DeleteDialog: {
      Body: 'Voulez-vous supprimer le document ?',
      Body_plural: 'Voulez-vous supprimer les documents ?',
      Title: 'Supprimer le document ?',
      Title_plural: 'Supprimer les documents ?',
      Subtitle: "Confirmer la poursuite de l'opération",
      Success: 'Document supprimé avec succès.',
      Success_plural: 'Documents supprimés avec succès.',
      Error: "Une erreur s'est produite pendant la suppression de votre document.",
      Error_plural: "Une erreur s'est produite pendant la suppression de vos documents"
    },
    Actions: {
      EmptySelectionText: 'Sélectionner au moins un document',
      EmptyDeleteSelectionTitle: 'Supprimer les documents',
      Delete: 'Supprimer',
      Download: 'Télécharger',
      ShareTrack: 'Partager la piste'
    },
    CustomTracks: {
      Title: 'Piste personnalisée',
      EditCustomTrack: 'Modifier la piste personnalisée',
      EditSuccessMessage: 'Piste personnalisée modifiée avec succès.',
      EditErrorMessage: "Une erreur s'est produite lors de la modification de la piste personnalisée.",
      VisualiseCustomTrack: 'Visualiser la piste personnalisée',
      Table: {
        TrackName: 'Nom de la piste',
        SaveDate: 'Enregistrer date',
        LastUseDate: 'Date de la dernière utilisation',
        OwnerFullName: 'Nom du créateur',
        ModuleOfOrigin: "Module d'origine"
      },
      DeleteDialog: {
        Body: 'Voulez-vous supprimer la piste personnalisée ?',
        Body_plural: 'Voulez-vous supprimer les pistes personnalisées ?',
        Title: 'Supprimer la piste personnalisée',
        Title_plural: 'Supprimer les pistes personnalisées',
        Subtitle: "Confirmez pour procéder avec l'opération.",
        Success: 'La piste personnalisée a été supprimée avec succès.',
        Success_plural: 'Les pistes personnalisées ont été supprimées avec succès.',
        Error: "Une erreur s'est produite lors de la suppression de votre piste personnalisée.",
        Error_plural: "Une erreur s'est produite lors de la suppression de vos pistes personnalisées."
      },
      RenameDialog: {
        Title: 'Modifier le nom de la piste personnalisée',
        Body: 'Saisissez un nom représentatif pour la piste personnalisée',
        NameInput: 'Nom de la piste personnalisée',
        Success: 'La piste personnalisée a été renommée avec succès.',
        Error: "Une erreur s'est produite lors du renommage de la piste personnalisée."
      },
      EmptyDialog: {
        Title: 'Supprimer les pistes personnalisées',
        Text: 'Vous devez sélectionner au moins une piste personnalisée.'
      },
      ShareCustomTrackErrorDialog: {
        Title: "Partage d'une piste personnalisée",
        Text: "Une erreur s'est produite lors du partage de la piste personnalisée."
      }
    }
  },
  Operations: {
    EmptyText: 'Ici se trouvent toutes les requêtes enregistrées dans la section Opérations',
    Table: {
      ResumePortfolioQueryTooltip: 'Ouvrir « Opérations » avec les filtres présélectionnés dans {{queryName}}'
    }
  },
  Info: {
    User: 'Utilisateur',
    LoginAs: 'Se connecter sur un autre compte utilisateur',
    Level: 'Niveau',
    Office: 'Bureau',
    Subscription: 'Inscription',
    NameAndSurname: 'Prénom et Nom',
    JobTitle: "Titre d'emploi",
    Telephone: 'Téléphone',
    CompanyName: 'Nom de la société',
    Country: 'Pays'
  },
  Account: {
    Password: {
      Password: 'Mot de passe',
      Current: 'Actuel',
      ChangePasswordTitle: 'Changer mot de passe',
      ChangePassword: 'Changer mot de passe',
      EnterPassword: 'Saisir le nouveau mot de passe',
      OldPassword: 'Ancien mot de passe',
      NewPassword: 'Nouveau mot de passe',
      ConfirmPassword: 'Confirmer le mot de passe'
    },
    Language: {
      Language: 'Langue',
      Current: 'Actuelle',
      ChangeLanguageTitle: 'Changer langue',
      ChangeLanguage: 'Changer la langue des contenus'
    },
    Currency: {
      Title: 'Devise',
      Current: 'Actuelle',
      ChangeCurrencyTitle: 'Changer devise',
      ChangeCurrency: 'Changer la devise des contenus'
    },
    Subscription: {
      Title: 'Mon abonnement (appels)',
      Available: 'Disponible',
      AvailableTooltip: "Appels restants sur l'abonnement disponible pour votre compte",
      Used: 'Utilisés',
      UsedTooltip: 'Appels dépensés sur votre compte',
      Total: 'Total',
      TotalTooltip: 'Appels achetés pour votre compte',
      Validity: 'Validité',
      ValidityInfo: 'Du {{from}} au {{to}}',
      CreditInfoWithoutCompanyName: " pour mettre à jour votre abonnement ou acheter plus d'appels",
      Error: "Les limites de consommation n'ont pas pu être chargée."
    },
    Contact: {
      Title: 'Contacts',
      User: 'Utilisateur',
      Email: 'E-mail',
      UpdateInfoWithoutCompanyName: ' pour modifier vos contacts'
    }
  },
  TargetingTab: {
    SavedQuery: 'Requête enregistrée',
    DownloadedDocuments: 'Documents téléchargés'
  },
  QueryDetail: {
    VisualiseQuery: 'Visualiser la requête',
    Monitored: 'Suivie'
  },
  ReleaseNotes: {
    Title: 'Dernières actualités',
    NewNote: 'Note sur nouvelle version',
    NoteTitle: 'Titre',
    NoteDescription: 'Brève description',
    NoteContent: 'Contenu',
    WhatsNew: 'Les nouveautés',
    GoToNewsPage: 'Aller à la page Dernières actualités pour voir les autres nouveautés'
  },
  Configuration: {
    ConfigureMargoTitle: 'Configurer Margo',
    CreateOfficeConfiguration: 'Créer la configuration du bureau',
    CreateUserConfiguration: 'Créer une configuration utilisateur',
    SearchOfficePlaceholder: 'Rechercher le nom du bureau',
    SearchUserPlaceholder: "Rechercher le nom d'utilisateur",
    EmptyText: 'Vous trouverez ici la configuration enregistrée.',
    ViewConfigurationTitle: 'Configuration pour',
    AddConfiguration: {
      OfficeTitle: 'Sélectionnez un ou plusieurs bureaux',
      OfficeDescription:
        "Sélectionnez un ou plusieurs bureaux pour lesquels vous souhaitez créer une configuration.\nVous créez une configuration de bureau, qui s'applique à tous les utilisateurs actuels et futurs. Vous pouvez modifier la configuration à tout moment.",
      UsersTitle: 'Sélectionnez un ou plusieurs utilisateurs',
      UsersDescription:
        "Sélectionnez un ou plusieurs utilisateurs pour lesquels vous souhaitez créer une configuration.\nSi l'utilisateur appartient à un bureau configuré, la configuration de l'utilisateur écrasera celle du bureau.",
      ParametersTitle: 'Sélectionnez les paramètres',
      ParametersDescription:
        'Sélectionnez les paramètres sur lesquels les entreprises seront filtrées.\nSi vous avez sélectionné un utilisateur appartenant à un bureau configuré, la liste ci-dessous présente le filtre Office appliqué.\nVous pouvez écraser la configuration Office uniquement pour les utilisateurs sélectionnés, en modifiant ou en supprimant tous les filtres.',
      FullMargoLabel: 'Désactiver tous les filtres et afficher la Margo complete',
      ConfigurationSuccessful: 'Configuration appliquée avec succès',
      ConfigurationSuccessfulDescription: 'Vous pouvez à tout moment modifier la configuration depuis Votre Compte'
    },
    DeleteConfiguration: {
      Title: 'Supprimer la configuration',
      Subtitle: 'Voulez-vous vraiment supprimer la configuration?',
      Body: "La visibilité de l'utilisateur ne sera plus limitée aux paramètres sélectionnés lors de la configuration.",
      Success: 'Configuration supprimée',
      Error: 'There was error while deleting configuration.'
    },
    Table: {
      UserName: "Nom d'utilisateur",
      OfficeName: 'Nom du Bureau',
      Creator: 'Créateur de la configuration',
      UpdatedAt: 'Date de la dernière mise à jour'
    },
    Offices: 'Bureaux',
    Users: 'Utilisateurs',
    UserTabTitle: 'Utilisateur',
    OfficeTabTitle: 'Bureau',
    AppliedFilterTabTitle: 'Filtres appliqués',
    AlreadyConfigured: 'Déjà configuré',
    FullMargoLabelForView: "Aucun filtre appliqué : l'utilisateur visualise le Margo complet"
  }
}
