// eslint-disable-next-line import/no-default-export
export default {
  PageTitle: 'Enter a contact for a company',
  SaveAddAnotherContact: 'Save and add another contact',
  CompanyIdentificationIta: 'Tax Code/Vat Code',
  CompanyIdentificationGer: 'ONR number/Vat code',
  CompanyIdentificationSui: 'Business identification number/Vat code',
  CompanyIdentificationAt: 'Company book number/Vat code',
  CompanyIdentificationSuiAt: 'Business identification number/Company book number/Vat code',
  ClassificationIta: 'Primary Ateco (2007)',
  ClassificationGer: 'WZ2008 Classification',
  ClassificationSui: 'NOGA',
  ClassificationAt: 'OENACE 2008',
  ClassificationSuiAt: 'NOGA/OENACE 2008',
  CompanyCity: 'Company City',
  Contact: 'Contact',
  JobTitle: 'Job Title',
  Office: 'Office',
  CallAllowed: 'Call Allowed',
  EmailOptOut: 'Email Opt Out',
  LeadQualification: 'Lead Qualification',
  ExpandTooltip: 'Click to expand this section',
  Challenges: 'Challenges',
  DecisionMaker: 'Decision Maker/Has an influence',
  MoneyBudget: 'Is there spending availability?',
  Priority: 'Priority',
  Notes: 'Notes',
  DisabledTooltip: 'Select company first',
  CreateErrorTitle: 'Error while creating contact',
  CreateErrorDescription: 'Please try again later or contact customer support',
  CreateSuccessTitle: 'Contact created successfuly',
  CreateSuccessDescription: 'You will be redirected to previous page',
  ChallengesPlaceholder: 'What are the contact needs?',
  PriorityPlaceholder: 'Within Q1 2024, Next year...',
  SearchCompanyPlaceholder: 'Search for the company by name',
  SearchByIdPlaceholder: 'Search for the company by ',
  CompanyRequired: 'Company is required',
  CountryRequired: 'Country is required',
  InsertNewContact: 'Insert new contact',
  DownloadList: 'Download list',
  SearchBy: 'Search for contact by',
  ByCompanyName: 'company name',
  ByContactInformation: 'contact information',
  ByContactPlaceholder: 'Find contact by Name, Surname, Job Title or Email',
  ByCompanyPlaceholder: 'Find contact by company name',
  NameAndSurname: 'Name and Surname',
  PostedBy: 'Posted by',
  DeleteContact: {
    Title: 'Delete contact',
    Subtitle: 'Permanently delete contact information?',
    Body: 'It will no longer be possible to recover them and you will have to re-enter them manually',
    Success: 'Contact was successfully deleted.',
    Error: 'There was error while deleting contact.'
  },
  UpdateSuccessTitle: 'Contact updated successfuly',
  UpdateErrorTitle: 'Error while updating contact',
  EditNoteTitle: 'Edit note',
  NoteUpdated: 'Note updated successfuly',
  NoteUpdateError: 'Error while updating note',
  CompanyReport: {
    ManageContacts: 'Manage contacts',
    EmptyText: 'Here you will find all your contacts for this company.'
  },
  WidgetInputPlaceholder: 'Find contacts by name, surname, email or job title',
  EditTooltip: 'Edit contact',
  DeleteTooltip: 'Delete contact',
  AddContact: 'Add contact'
}
