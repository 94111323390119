import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { CancelToken } from 'axios'
import _ from 'lodash'
import { getSaveListOrderBy, getFunnelCounters, getSimilarityQuery, getTargetingFiltersForPortfolio } from './api'
import { FunnelCountersRequest } from './types'
import { cacheAllTheTime } from '../../features/queryclient'
import { FilterQueryDto } from '../filters/types'
import { SimilarityType } from '../../features/filters/types'

export const getProspectingKeys = {
  All: () => [{ level1: 'prospecting' }] as const,
  GetFunnelCounters: (data: FunnelCountersRequest) =>
    [{ ...getProspectingKeys.All()[0], level2: 'getFunnelCounters', data }] as const,
  OrderByOptions: () => [{ ...getProspectingKeys.All()[0], level2: 'useOrderByOptions' }] as const,
  ProspectingCompanies: () => [{ ...getProspectingKeys.All()[0], level2: 'getProspectingCompanies' }] as const,

  ProspectingMapCompanies: () => [{ ...getProspectingKeys.ProspectingCompanies()[0], level3: 'mapCompanies' }] as const,
  SimilarityQuery: (similarity: SimilarityType | undefined) => [
    { ...getProspectingKeys.All()[0], level2: 'similarityQuery', similarity }
  ],
  GetTargetingFiltersForPortfolio: (
    portfolioIds: string[] | undefined,
    companyStatus: string,
    cancelToken?: CancelToken
  ) =>
    [
      {
        ...getProspectingKeys.All()[0],
        level2: 'getTargetingFiltersForPortfolio',
        portfolioIds,
        companyStatus,
        cancelToken
      }
    ] as const
}

export const useGetFunnelCounters = (data: FunnelCountersRequest, enabled: boolean) =>
  useQuery({
    queryKey: getProspectingKeys.GetFunnelCounters(data),
    queryFn: ({ queryKey: [queryKeys] }) => getFunnelCounters(queryKeys.data),
    enabled
  })

export const useSaveListOrderByOptions = () =>
  useQuery({ queryKey: getProspectingKeys.OrderByOptions(), queryFn: getSaveListOrderBy })

export const useGetSimilarityQuery = (
  similarity: SimilarityType | undefined,
  options?: Omit<
    UseQueryOptions<
      FilterQueryDto | null,
      unknown,
      FilterQueryDto,
      ReturnType<typeof getProspectingKeys.SimilarityQuery>
    >,
    'queryKey' | 'queryFn'
  >
) => {
  return useQuery({
    queryKey: getProspectingKeys.SimilarityQuery(similarity),
    queryFn: ({ queryKey: [queryKeys] }) => {
      if (queryKeys.similarity && queryKeys.similarity.portfolioId) {
        return getSimilarityQuery({
          similarityFilterPortfolioIds: [queryKeys.similarity.portfolioId],
          companyPortfolioStatus: queryKeys.similarity.status,
          data: queryKeys.similarity.data
        }).then(res => res.data)
      }
      return null
    },
    ...cacheAllTheTime,
    ...options
  })
}

export const useGetTargetingFiltersForPortfolioQuery = (
  portfolioIds: string[] | undefined,
  companyStatus: string,
  options?: UseQueryOptions<
    FilterQueryDto | null,
    unknown,
    FilterQueryDto | null,
    ReturnType<typeof getProspectingKeys.GetTargetingFiltersForPortfolio>
  >,
  cancelToken?: CancelToken
) => {
  return useQuery({
    queryKey: getProspectingKeys.GetTargetingFiltersForPortfolio(portfolioIds, companyStatus, cancelToken),
    queryFn: () => {
      if (portfolioIds === undefined || _.isEmpty(portfolioIds)) return null

      return getTargetingFiltersForPortfolio(portfolioIds, companyStatus, cancelToken).then(res => res.data)
    },
    ...cacheAllTheTime,
    ...options
  })
}
