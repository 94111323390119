import { createGlobalStyle } from 'styled-components/macro'
import { HEADER_HEIGHT } from '../../utils/theme'
import { Layout } from '../Grid'
import { MainMenuWrapper } from '../MainMenu'
import { FooterWrapper } from './Footer'

export const GlobalStyle = createGlobalStyle`
  body, #root {
    min-height: 100%;
    overflow-x: hidden;
  }

  /* GLOB-5443 - styles for body element when a dialog is open */
  body.with-dialog-open {
    overflow: hidden !important;
  }

  #root {
    display: flex;
    position: relative;
    flex-direction: column;
    > ${Layout} {
      flex: 1 1 auto;
      margin-top: calc(${HEADER_HEIGHT}px + 40px);
      margin-bottom: 40px;
    }
    ${MainMenuWrapper},
    ${FooterWrapper} {
      flex: 0 0 auto;
    }
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: ${({ theme }) => theme.fontFamily} !important;
    font-size: ${({ theme }) => theme.fontSizes.small};

    a {
      text-decoration: none;
    }

    .ant-notification {
      top: 120px !important;
    }

    .ant-notification-notice {
      border-radius: 8px;
    }

    .notification-wrapper {
      padding: 0 !important;
    }

    .notification-event,
    .notification-wrapper {
      background: ${({ theme }) => theme.colors.main} !important;
      color: ${({ theme }) => theme.colors.white} !important;
    }

    .notification-event .ant-notification-notice-message,
    .notification-wrapper .ant-notification-notice-message {
      padding: 0 !important;
      width: 100% !important;
    }

    .notification-progress {
      padding: 10px 8px 2px 8px !important;
      background: ${({ theme }) => theme.colors.main} !important;
    }

    .ant-notification-notice-message {
      margin-bottom: 0 !important;
      padding-inline-end: 0 !important;
    }

    .ant-notification .ant-notification-notice-close {
      display: none !important;
    }

    .tree-select-layer.ant-popover {
      padding-left: 21px;
    }

    .tree-select-layer .ant-popover-inner-content {
      padding: 0;
    }

    .tree-select-layer .ant-popover-arrow {
      display: none;
    }

    .ant-progress-inner {
      background-color: rgba(255, 255, 255, 0.2);
    }

    .ant-layout {
      background: #fff;
    }

    .rf-field.ant-form-item {
      margin: 0;
      label {
        color: rgba(0, 0, 0, 0.65) !important;
      }
    }

    .ant-tooltip.info-tooltip {
      .ant-tooltip-inner {
        padding: 16px;
        background-color: ${({ theme }) => theme.colors.pumpkinOrange};
        white-space: pre-line;
      }
      .ant-tooltip-arrow {
        border-color: ${({ theme }) => theme.colors.pumpkinOrange};
        ::before {
          background-color: ${({ theme }) => theme.colors.pumpkinOrange};
        }
      }
    }

    /*
      Z-index hierarchy:
      1. MainMenuWrapper (44000) is on top of everything except Modals-mask(44500), Modals(45000) and Tooltips(46000)
      2. Select-dropdowns (normal:43500) in modals need to be on top of everything except tooltip (modal-selector:45500)
    */

    /* Need to be in front of everything*/
    .ant-tooltip {
      max-width: 50vw;
      z-index: 46000;
    }

    /* Select-dropdown need to be in front of everything except MainMenuWrapper, Modals, Tooltips */
    .ant-select-dropdown {
      z-index: 43500;
    }

    /* Language switch need to be in front of Main Menu Wrapper */
    .ant-dropdown.language-switch {
      z-index: 44100;
    }

    /* Select-dropdown in modal need to be on top of everything except Tooltips */
    .dialog-dropdown-select {
      z-index: 45500 !important;
    }

    /* Antd Modal customization */
    .ant-modal-root {
      .ant-modal-mask {
        background-color: rgba(0, 60, 125, 0.6);
        z-index: 44500;
      }

      .ant-modal-wrap {
        right: auto;
        bottom: auto;
        width: 100vw;
        height: 100vh;
      }

      .margo-dialog {
        z-index: 45000;
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-modal {
          position: static;
          margin: 0;
          padding: 0;
          max-height: 100vh;
        }

        .ant-modal-close-x {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .ant-modal-content {
          border-top: 4px solid ${({ theme }) => theme.colors.main};
          padding: 0px;
        }

        .ant-modal-header {
          padding: 14px;
          box-sizing: border-box;
          border-bottom: 1px solid ${({ theme }) => theme.colors.veryLightPink};
        }

        .ant-modal-body {
          padding: 0;
        }

        .ant-modal-footer {
          padding: 20px 40px;
          text-align: unset;
          border-top: 1px solid ${({ theme }) => theme.colors.veryLightPink};
        }
      }
    }

    /* Antd Table customization */
    .ant-table-row.highlighted-row > :first-child {
      border-left: 8px solid ${({ theme }) => theme.colors.main};
    }

    .company-note-dialog .ant-spin-nested-loading > div > .ant-spin {
      max-height: none;
    }

    .ant-select .ant-select-clear {
      right: 40px;
      background: none;
    }

    .ant-card-loading-content {
        .ant-card-loading-block {
          height: 16px;
          margin: 8px 0;
      }
    }

    .ant-notification .confirmation-message {
      padding: 0;
      background: ${({ theme }) => theme.colors.main};
      box-shadow: 0 4px 8px 0 rgba(0, 60, 125, 0.2);
      .ant-notification-notice-close {
        display: none;
      }
      .ant-notification-notice-icon {
        height: 100%;
      }
    }

    /* Recharts customization */
    .recharts-responsive-container {
      position: relative;
    }
  }

  .ant-popover {
    z-index: 44200;

    .ant-popover-content {


      .ant-popover-inner {
        border-radius: 4px;
        border: 1px solid ${({ theme }) => theme.colors.veryLightPink};
        border-top: 4px solid ${({ theme: { colors } }) => colors.main};
      }

      .ant-popover-inner-content {
        padding: 0 20px 20px 20px;
      }
    }
  }

.ant-select-dropdown {
  &.no-scroll {
    .ant-select-dropdown-menu, .rc-virtual-list-holder {
      max-height: unset !important;
    }
  }

  && .ant-select-tree-treenode {
    width: 100%;
    padding: 2px 8px;

    .ant-select-tree-node-content-wrapper {
      padding: 2px 8px;
    }
  }
}

.ant-form-item-row {
   flex-direction: column;
}

.ant-form-item .ant-form-item-label {
  text-align: start;
}



/* Google recaptcha tag  */
.grecaptcha-badge {
  bottom: 100px !important;
  z-index: 44000;
}

`
