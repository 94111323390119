import _ from 'lodash'
import { useQuery, useInfiniteQuery, UseQueryOptions, keepPreviousData } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { CompoundExpression } from '@api/expressionTypes'
import {
  getCampaign,
  getCampaignStats,
  getCampaigns,
  getCampaignsStatusCount,
  getCompanies,
  getAssignedUserInfo,
  getCampaignMapCompanies,
  getMapDistribution,
  getCompanyNotes,
  getProductsList,
  getAssignedUserList,
  getCampaingCustomVariables,
  getBestAssignedUsersByTask,
  getBranchesWithUsers
} from './api'
import { CampaignStatus, AssignedUser } from '../../features/salestool/types'
import { OrderByDirection, OrderByRequest } from '../types'
import {
  CampaignFields,
  CampaignCompaniesReq,
  CampaignRequestParams,
  PageType,
  SelectListResponse,
  AssignedUserListType,
  CampaignCompaniesForMap
} from './types'
import { cacheAllTheTime } from '../../features/queryclient'
import { createCampaignCompaniesMapReq, mapCampaign } from '../../features/campaignDetailPage/mappers'
import { MapPositionData, Pagination } from '../../types'
import { convertCompoundExpressionForBackend } from '../../features/filters/utils'

export const getSalestoolKeys = {
  All: () => [{ level1: 'salestool' }] as const,
  AllBasedOnCampaignId: (campaignId: string) => [{ ...getSalestoolKeys.All()[0], campaignId }] as const,

  GetCampaign: (campaignId: string) => [{ ...getSalestoolKeys.All()[0], level2: 'getCampaign', campaignId }] as const,

  GetCampaignStats: (campaignId: string) =>
    [{ ...getSalestoolKeys.GetCampaign(campaignId)[0], level3: 'getCampaignStats' }] as const,
  GetCampaignStatsWithSalesId: (campaignId: string, assignedUserId?: string) =>
    [{ ...getSalestoolKeys.GetCampaignStats(campaignId)[0], assignedUserId }] as const,

  GetCampaigns: () => [{ ...getSalestoolKeys.All()[0], level2: 'getCampaigns' }] as const,

  GetCampaignsWithData: (params: CampaignRequestParams) => [{ ...getSalestoolKeys.GetCampaigns()[0], params }] as const,

  GetCampaignsStatusCount: () =>
    [{ ...getSalestoolKeys.GetCampaigns()[0], level3: 'getCampaignsStatusCount' }] as const,

  GetInfiniteCampaignsWithData: (props: GetInfiniteCampaignsProps) =>
    [
      {
        ...getSalestoolKeys.GetCampaigns()[0],
        level3: 'Infinite',
        ...props
      }
    ] as const,

  GetCampaignCompaniesForCampaignId: (campaignId: string) =>
    [{ ...getSalestoolKeys.All()[0], level2: 'getCampaignCompanies', campaignId }] as const,
  GetCampaignCompaniesWithData: (
    campaignId: string,
    size: number,
    query: CompoundExpression | undefined,
    sortBy: OrderByRequest[],
    pageType: PageType
  ) =>
    [{ ...getSalestoolKeys.GetCampaignCompaniesForCampaignId(campaignId)[0], size, query, sortBy, pageType }] as const,

  GetInfiniteCampaignCompaniesWithData: (
    campaignId: string,
    pagination: Pagination,
    query: CompoundExpression | undefined,
    sortBy: OrderByRequest[],
    pageType: PageType
  ) =>
    [
      {
        ...getSalestoolKeys.GetCampaignCompaniesForCampaignId(campaignId)[0],
        level3: 'Infinite',
        pagination,
        query,
        sortBy,
        pageType
      }
    ] as const,

  CampaignMapCompaniesForCampaignId: (campaignId: string) => [
    { ...getSalestoolKeys.GetCampaignCompaniesForCampaignId(campaignId)[0], level3: 'mapCompanies' }
  ],
  CampaignMapCompaniesWithData: (
    campaignId: string,
    mapPositionData: MapPositionData | undefined,
    mapMaxZoom: number,
    query: CompoundExpression | undefined
  ) => [{ ...getSalestoolKeys.CampaignMapCompaniesForCampaignId(campaignId)[0], mapPositionData, mapMaxZoom, query }],

  GetAssignedUserInfo: (assignedUserId: string, campaignId?: string) =>
    [{ ...getSalestoolKeys.All()[0], level2: 'getAssignedUserInfo', assignedUserId, campaignId }] as const,

  GetMapDistribution: (campaignId: string, query: CompoundExpression | undefined) =>
    [{ ...getSalestoolKeys.All()[0], level2: 'getMapDistribution', campaignId, query }] as const,

  GetCompanyNotes: (companyId: string) =>
    [{ ...getSalestoolKeys.All()[0], level2: 'getCompanyNotes', companyId }] as const,
  GetProductsList: () => [{ ...getSalestoolKeys.All()[0], level2: 'getProductsList' }] as const,
  GetAssignedUserList: (listType: AssignedUserListType) =>
    [{ ...getSalestoolKeys.All()[0], level2: 'getAssignedUserList', listType }] as const,
  GetBranchesWithUsers: () => [{ ...getSalestoolKeys.All()[0], level2: 'getBranchesWithUsers' }] as const,
  CampaignCustomVariables: (campaignId: string) =>
    [{ ...getSalestoolKeys.All()[0], level2: 'getCampaignCustomVariables', campaignId }] as const,

  GetBestAssignedUsersByTask: (campaignId: string, taskId: string | undefined) =>
    [{ ...getSalestoolKeys.GetCampaign(campaignId)[0], level3: 'getBestAssignedUsersByTask', taskId }] as const
}

// TODO: it is called multiple times - neeed to add cacheAllTheTime and properly invalid when needed
export const useGetCampaign = (campaignId: string) =>
  useQuery({
    queryKey: getSalestoolKeys.GetCampaign(campaignId ?? ''),
    queryFn: ({ queryKey: [queryKeys] }) => {
      if (queryKeys.campaignId !== '') return getCampaign(queryKeys.campaignId).then(mapCampaign)
      return null
    },
    ...cacheAllTheTime
  })

export const useGetCampaignStats = (campaignId: string, assignedUserId?: string) =>
  useQuery({
    queryKey: getSalestoolKeys.GetCampaignStatsWithSalesId(campaignId, assignedUserId),
    queryFn: ({ queryKey: [queryKeys] }) => getCampaignStats(queryKeys.campaignId, queryKeys.assignedUserId),
    ...cacheAllTheTime
  })

export const useGetCampaigns = (params: CampaignRequestParams, enabled = true) =>
  useQuery({
    queryKey: getSalestoolKeys.GetCampaignsWithData(params),
    queryFn: ({ queryKey: [queryKeys] }) => getCampaigns(queryKeys.params),
    ...cacheAllTheTime,
    enabled
  })

export const useGetCampaignsStatusCount = () =>
  useQuery({
    queryKey: getSalestoolKeys.GetCampaignsStatusCount(),
    queryFn: getCampaignsStatusCount,
    ...cacheAllTheTime
  })

export type GetInfiniteCampaignsProps = {
  top: number
  campaignStatus: CampaignStatus | undefined
  isArchived: boolean | undefined
  nameLike: string | undefined
  saleUserName: string | undefined
  sortOrder: OrderByDirection | undefined
  sortPropertyName: CampaignFields | undefined
}

export const useGetInfiniteCampaigns = (props: GetInfiniteCampaignsProps) =>
  useInfiniteQuery({
    queryKey: getSalestoolKeys.GetInfiniteCampaignsWithData(props),
    queryFn: ({ queryKey: [queryKeys], pageParam = 0 }) =>
      getCampaigns({
        top: queryKeys.top,
        skip: pageParam,
        nameLike: queryKeys.nameLike,
        campaignStatus: queryKeys.campaignStatus,
        saleUserName: queryKeys.saleUserName,
        isArchive: queryKeys.isArchived,
        sortOrder: queryKeys.sortOrder,
        sortPropertyName: queryKeys.sortPropertyName
      }),
    initialPageParam: 0,
    getNextPageParam: (lastResp, allPages) => {
      const count = _.sum(_.map(allPages, 'campaigns.length'))
      return lastResp?.campaignsCount <= count ? undefined : count
    }
  })

export const useGetCampaignCompanies = (campaignId: string, data: CampaignCompaniesReq) => {
  const convertedQuery = data.query ? convertCompoundExpressionForBackend(data.query) : undefined

  return useQuery({
    queryKey: getSalestoolKeys.GetCampaignCompaniesWithData(
      campaignId,
      data.pagination.size,
      convertedQuery,
      data.sortBy,
      data.pageType
    ),
    queryFn: ({ queryKey: [queryKeys] }) =>
      getCompanies(queryKeys.campaignId, {
        pageType: queryKeys.pageType,
        query: queryKeys.query,
        pagination: { position: 0, size: queryKeys.size },
        sortBy: queryKeys.sortBy
      }).then(res => res.data),
    ...cacheAllTheTime
  })
}

export const useGetInfiniteCampaignCompanies = (campaignId: string, data: CampaignCompaniesReq) => {
  const convertedQuery = data.query ? convertCompoundExpressionForBackend(data.query) : undefined

  return useInfiniteQuery({
    queryKey: getSalestoolKeys.GetInfiniteCampaignCompaniesWithData(
      campaignId,
      data.pagination,
      convertedQuery,
      data.sortBy,
      data.pageType
    ),
    queryFn: ({ queryKey: [queryKeys], pageParam }) =>
      getCompanies(queryKeys.campaignId, {
        pageType: queryKeys.pageType,
        query: queryKeys.query,
        pagination: { ...queryKeys.pagination, position: pageParam },
        sortBy: queryKeys.sortBy
      }).then(res => res.data),
    initialPageParam: 0,
    getNextPageParam: (lastResp, allPages) => {
      const count = _.sum(_.map(allPages, 'data.length'))
      return lastResp?.totalCount <= count ? undefined : allPages.length
    },
    ...cacheAllTheTime
  })
}

export const useGetCampaignMapCompanies = (
  campaignId: string,
  mapPositionData: MapPositionData | undefined,
  mapMaxZoom: number,
  query: CompoundExpression | undefined
) => {
  const convertedQuery = query ? convertCompoundExpressionForBackend(query) : undefined

  return useQuery<
    CampaignCompaniesForMap | undefined,
    AxiosError,
    CampaignCompaniesForMap,
    ReturnType<typeof getSalestoolKeys.CampaignMapCompaniesWithData>
  >({
    queryKey: getSalestoolKeys.CampaignMapCompaniesWithData(campaignId, mapPositionData, mapMaxZoom, convertedQuery),
    queryFn: ({ queryKey: [queryKeys] }) => {
      if (!queryKeys.mapPositionData) return (async () => undefined)()
      return getCampaignMapCompanies(
        queryKeys.campaignId,
        createCampaignCompaniesMapReq(queryKeys.mapPositionData, queryKeys.mapMaxZoom, queryKeys.query)
      ).then(res => res.data)
    },
    placeholderData: keepPreviousData
  })
}

export const useGetAssignedUserInfo = (assignedUserId: string, campaignId?: string) =>
  useQuery({
    queryKey: getSalestoolKeys.GetAssignedUserInfo(assignedUserId, campaignId),
    queryFn: ({ queryKey: [queryKeys] }) => getAssignedUserInfo(queryKeys.assignedUserId, queryKeys.campaignId)
  })

export const useGetMapDistribution = (campaignId: string, query: CompoundExpression | undefined) =>
  useQuery({
    queryKey: getSalestoolKeys.GetMapDistribution(campaignId, query),
    queryFn: ({ queryKey: [queryKeys] }) => getMapDistribution(queryKeys.campaignId, queryKeys.query)
  })

export const useGetCompanyNotes = (companyId: string) =>
  useQuery({
    queryKey: getSalestoolKeys.GetCompanyNotes(companyId),
    queryFn: ({ queryKey: [queryKeys] }) => getCompanyNotes(queryKeys.companyId),
    ...cacheAllTheTime
  })

export const useGetProductsList = <SelectType = SelectListResponse>(
  options?: Omit<
    UseQueryOptions<SelectListResponse, AxiosError, SelectType, ReturnType<typeof getSalestoolKeys.GetProductsList>>,
    'queryKey' | 'queryFn'
  >
) =>
  useQuery({ queryKey: getSalestoolKeys.GetProductsList(), queryFn: getProductsList, ...cacheAllTheTime, ...options })

export const useGetAssignedUserList = <SelectType = AssignedUser[]>(
  listType: AssignedUserListType = 'Simple',
  options?: Omit<
    UseQueryOptions<AssignedUser[], AxiosError, SelectType, ReturnType<typeof getSalestoolKeys.GetAssignedUserList>>,
    'queryKey' | 'queryFn'
  >
) =>
  useQuery({
    queryKey: getSalestoolKeys.GetAssignedUserList(listType),
    queryFn: ({ queryKey: [queryKeys] }) => getAssignedUserList(queryKeys.listType),
    ...cacheAllTheTime,
    ...options
  })

export const useGetBranchesWithUsers = () =>
  useQuery({
    queryKey: getSalestoolKeys.GetBranchesWithUsers(),
    queryFn: getBranchesWithUsers,
    ...cacheAllTheTime
  })

export const useGetCampaignCustomVariablesTypes = (campaignId: string) =>
  useQuery({
    queryKey: getSalestoolKeys.CampaignCustomVariables(campaignId),
    queryFn: ({ queryKey: [queryKeys] }) => getCampaingCustomVariables(queryKeys.campaignId),
    select: data => _.map(data.customVars, ({ type }) => type)
  })

export const useGetBestAssignedUsersByTask = (campaignId: string, taskId: string | undefined) =>
  useQuery({
    queryKey: getSalestoolKeys.GetBestAssignedUsersByTask(campaignId, taskId),
    queryFn: ({ queryKey: [queryKeys] }) => {
      if (queryKeys.taskId !== undefined) return getBestAssignedUsersByTask(queryKeys.campaignId, queryKeys.taskId)
      return null
    },
    enabled: taskId !== undefined,
    ...cacheAllTheTime
  })
