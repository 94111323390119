import axios, { CancelToken } from 'axios'
import {
  SaveListOrderByOptionsResponse,
  FunnelCountersRequest,
  FunnelCountersResponse,
  EnrichmentInfoResponse,
  SimilarityReq
} from './types'
import {
  DomainNodesDataRequest,
  DomainNodesDataResponse,
  FilterQueryDto,
  SearchDomainNodesRequest
} from '../filters/types'

export const getSaveListOrderBy = () =>
  axios.get<SaveListOrderByOptionsResponse>('/api/prospecting/get-save-list-order-by').then(x => x.data)

export const getFunnelCounters = (data: FunnelCountersRequest) =>
  axios.post<FunnelCountersResponse>('/api/prospecting/funnel-counters', data).then(x => x.data)

export const getProspectingDomainNodesData = (data: DomainNodesDataRequest, cancelToken?: CancelToken) =>
  axios.post<DomainNodesDataResponse>('/api/Prospecting/domain-nodes-data', data, { cancelToken })

export const getProspectingSearchDomainNodesDataPath = (data: SearchDomainNodesRequest) =>
  axios.post<DomainNodesDataResponse>('/api/Prospecting/search-domain-nodes-data', data)

export const getSimilarityQuery = (data: SimilarityReq) =>
  axios.post<FilterQueryDto>('/api/prospecting/similarity-filters-query', data)

export const getEnrichmentInfo = (companyUnitIds: string[], cancelToken?: CancelToken) =>
  axios.post<EnrichmentInfoResponse>('/api/prospecting/get-enrichment-info', { companyUnitIds }, { cancelToken })

export const getTargetingFiltersForPortfolio = (
  portfolioIds: string[],
  companyStatus: string,
  cancelToken?: CancelToken
) =>
  axios.post<FilterQueryDto>(
    '/api/prospecting/targeting-filters-query',
    {
      similarityFilterPortfolioIds: portfolioIds,
      companyPortfolioStatus: companyStatus
    },
    { cancelToken }
  )
