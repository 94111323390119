/* eslint-disable camelcase */
/* eslint-disable import/no-default-export */
export default {
  Title: 'Ihr Konto',
  Navigation: {
    Account: 'Konto',
    SavedReport: 'Gespeicherte Reports',
    PortfolioManagement: 'Portfolio Management',
    Targeting: 'Marktanalyse',
    Prospecting: 'Prospect Finder',
    Operations: 'Operatione',
    SalesTool: 'Sales Tool',
    Products: 'Produkte',
    GoalsTasks: 'Ziele & Aufgaben',
    ReleaseNotes: 'Neuesten Nachrichten',
    Configuration: 'Konfiguration',
    Appointments: 'Termine',
    Contacts: 'Kontakte'
  },
  Goal: {
    AddGoal: 'Ziel hinzufügen',
    AddGoalTooltip: 'Erstellen Sie ein Ziel, das Sie in den Kampagnen auswählen können',
    EditGoal: {
      Title: 'Das Ziel ändern',
      Body: 'Geben Sie einen repräsentativen Namen für das Ziel ein',
      NameInput: 'Name des Ziels',
      Success: 'Ziel erfolgreich geändert.',
      Error: 'Beim Ändern des Ziels ist ein Fehler aufgetreten.'
    },
    DeleteGoal: {
      Title: 'Löschen Sie das Ziel',
      Subtitle: 'Sind Sie sicher, dass Sie löschen möchten {{goalName}}?',
      Body: 'Die Aktion wird nur ausgeführt, wenn das Ziel keiner aktiven Kampagne zugewiesen wurde; Andernfalls wird die Aktion abgelehnt.',
      Success: 'Ziel erfolgreich gelöscht.',
      Error: 'Beim Löschen des Ziels ist ein Fehler aufgetreten.',
      ErrorAssignedToActiveCampaign:
        'Das ausgewählte Ziel kann nicht gelöscht werden, da es einer aktiven Kampagne zugeordnet ist.',
      ErrorActiveActionsForSameOrganization:
        'Der Vorgang konnte aufgrund anderer ausstehender Aktionen nicht abgeschlossen werden. Bitte versuchen Sie es in einigen Minuten erneut.'
    },
    EditTask: {
      Title: 'Aufgabennamen ändern',
      Body: 'Entrez un nom représentatif pour la tâche',
      NameInput: 'Aufgabennname',
      Success: 'Aufgabe erfolgreich geändert',
      Error: 'Beim Ändern der Aufgabe ist ein Fehler aufgetreten.'
    },
    DeleteTask: {
      Title: 'Aufgabe löschen',
      Subtitle: 'Sind Sie sicher, dass Sie löschen möchten {{taskName}}?',
      Body: 'Die Aktion wird nur ausgeführt, wenn das genannte Ziel keiner aktiven Kampagne zugewiesen wurde; Andernfalls wird die Aktion verweigert.',
      Success: 'Aufgabe erfolgreich gelöscht',
      Error: 'Beim Löschen der Aufgabe ist ein Fehler aufgetreten.',
      ErrorAssignedToActiveCampaign:
        'Es ist nicht möglich, die ausgewählte Aufgabe zu löschen, da es Unternehmen in aktiven Kampagnen gibt, denen die erwähnte Aufgabe zugewiesen wurde. Um mit dem Löschen fortzufahren, entfernen Sie die Zuweisung innerhalb der Kampagne und versuchen Sie erneut, die Aufgabe zu entfernen.',
      ErrorActiveActionsForSameOrganization:
        'Der Vorgang konnte aufgrund anderer ausstehender Aktionen nicht abgeschlossen werden. Bitte versuchen Sie es in einigen Minuten erneut.'
    },
    CreateGoal: 'Ziel setzen',
    NameDesc: 'Vergeben Sie einen repräsentativen Namen für das zu erstellende Ziel.',
    GoalNamePlaceholder: 'Ziel-Name hinzufügen',
    GoalNameLabel: 'Ziel-Name',
    TasksHeader: 'Wählen Sie die Aufgaben, um Ihr Ziel zu erreichen',
    TaskDesc:
      'Geben Sie alle Aufgaben ein, die zum Erreichen dieses Ziels erforderlich sind (mindestens 3, höchstens 10). Niedrigere Zahlen kennzeichnen einen geringen Zielerreichungsgrad.',
    TaskPlaceholder: 'Aufgabenummer hinzufügen',
    TaskNumber: 'Aufgabennummer',
    LastTaskDesc: '* Diese Aufgabe wird als letzte Aufgabe der Kampagne betrachtet',
    AddTask: 'Aufgabe hinzufügen',
    Number: 'Nummer',
    Task: 'Aufgabe',
    Desc1: 'Die Zahl bei der Aufgabe zeigt den Zielerreichungsgrad.',
    Desc2: 'Niedrigere Zahlen kennzeichnen einen geringen Zielerreichungsgrad.',
    Table: {
      Name: 'Ziel',
      NumberOfTasks: 'Anzahl Aufgaben'
    },
    EmptyText: 'Hier finden Sie alle gespeicherten Ziele und Aufgaben.',
    Visualize: 'Visualisiere {{goal}}'
  },
  Queries: {
    Table: {
      Name: 'Gespeicherte Abfrage',
      CreatedAt: 'Datum speichern',
      LastUseDate: 'Datum der letzten Nutzung',
      Monitored: 'Verfolgen',
      ResumeQuery: 'Suche öffnen',
      Delete: 'Löschen',
      ResumePortfolioQueryTooltip: 'Öffnen Sie die Portfolio Management mit vorausgewählten Filtern aus {{queryName}}',
      ResumeProspectingQueryTooltip: 'Öffnen Sie die Suche mit den vordefinierten Filtern aus {{queryName}}',
      ResumeTargetingQueryTooltip: 'Öffnen Sie die Marktanalyse mit vorausgewählten Filtern aus {{queryName}}'
    },
    EmptyProspectingText: 'Hier finden Sie alle Abfragen, die im Bereich "Prospecting" gespeichert sind.',
    EmptyTargetingText: 'Hier finden Sie alle Abfragen, die im Bereich "Analyze Market" gespeichert sind.',
    DeleteDialog: {
      Title: 'Abfrage löschen',
      Title_plural: 'Anfragen löschen',
      Subtitle: 'Bitte bestätigen Sie die Fortsetzung des Vorgangs.',
      Body: 'Alle Daten, die sich auf die Abfrage beziehen, werden gelöscht.',
      Body_plural: 'Alle Daten, die sich auf die Anfragen beziehen, werden gelöscht.',
      Monitored: 'Wenn die Abfrage überwacht wird, wird das relative Portfolio nicht mehr aktualisiert.',
      Success: 'Abfrage wurde erfolgreich gelöscht.',
      Success_plural: 'Anfragen wurden erfolgreich gelöscht.',
      Error: 'Beim Löschen Ihres Abfrage ist ein Fehler aufgetreten.',
      Error_plural: 'Beim Löschen Ihrer Anfragen ist ein Fehler aufgetreten.',
      MonitoredQueryError:
        'Einige der ausgewählten Abfragen können nicht gelöscht werden, da es sich um überwachte Abfragen handelt. Um diese Abfragen zu löschen, löschen Sie bitte zuerst die zugehörigen überwachten Portfolios.'
    },
    ResumePortfolioQueryDialog: {
      Title: 'Wählen Sie das Portfolio aus, auf das die Abfrage angewendet werden soll',
      Text: 'Wählen Sie eines der verfügbaren Portfolios aus.'
    },
    Actions: {
      EmptyDeleteSelectionTitle: 'Abfrage löschen',
      EmptySelectionText: 'Sie müssen mindestens ein Abfrage auswählen',
      Delete: 'Löschen'
    }
  },
  Products: {
    Table: {
      Name: 'Produkt',
      CreatedAt: 'Datum der Einfügung'
    },
    AddProduct: 'Produkt hinzufügen',
    AddProductTooltip: 'Erstellen Sie ein Produkt, das Sie den Kampagnen zuordnen können',
    EditProduct: {
      Title: 'Produktname ändern',
      Body: 'Geben Sie einen repräsentativen Namen für das Produkt ein',
      NameInputLabel: 'Produktname',
      NameInputPlaceholder: 'Produktname',
      Success: 'Produkt erfolgreich geändert.',
      Error: 'Beim Ändern des Produkts ist ein Fehler aufgetreten.'
    },
    DeleteProduct: {
      Title: 'Produkt löschen',
      Subtitle: 'Sind Sie sicher, dass Sie {{productName}} löschen möchten?',
      Body: 'Aktion wird nur ausgeführt, wenn das genannte Produkt keiner aktiven Kampagne zugeordnet wurde; andernfalls wird die Aktion verweigert.',
      Success: 'Produkt wurde erfolgreich gelöscht.',
      Error: 'Beim Löschen des Produkts ist ein Fehler aufgetreten',
      ErrorAssignedToActiveCampaign:
        'Das ausgewählte Produkt kann nicht gelöscht werden, da es eine aktive Kampagne gibt, der das erwähnte Produkt zugewiesen wurde.',
      ErrorActiveActionsForSameOrganization:
        'Der Vorgang konnte aufgrund anderer ausstehender Aktionen nicht abgeschlossen werden. Bitte versuchen Sie es in einigen Minuten erneut.'
    },
    EmptyText: 'Hier finden Sie alle im Bereich "Produkte" gespeicherten Produkte',
    CreateProduct: 'Produkt erstellen',
    NameDesc: 'Geben Sie einen repräsentativen Namen des Produkts ein, das Sie erstellen.',
    ProductNamePlaceholder: 'Produktnamen einfügen',
    ProductNameLabel: 'Produktname'
  },
  PortfolioManagement: {
    EmptyText: 'Hier finden Sie alle im Bereich "Portfoliomanagement" gespeicherten Dokumente',
    Documents: 'Unterlagen',
    DocumentsTable: {
      Name: 'Dokument',
      CreatedAt: 'Gespeichertes Datum',
      MimeType: 'Erweiterung'
    },
    Queries: 'Anfragen',
    DeleteDialog: {
      Body: 'Möchten Sie ein Dokument löschen?',
      Body_plural: 'Möchten Sie Dokumente löschen?',
      Title: 'Dokument löschen',
      Title_plural: 'Dokumente löschen',
      Subtitle: 'Bitte bestätigen Sie die Fortsetzung des Vorgangs.',
      Success: 'Dokument wurde erfolgreich gelöscht.',
      Success_plural: 'Dokumente wurden erfolgreich gelöscht.',
      Error: 'Beim Löschen Ihres Dokuments ist ein Fehler aufgetreten.',
      Error_plural: 'Beim Löschen Ihrer Dokumente ist ein Fehler aufgetreten.'
    },
    Actions: {
      EmptySelectionText: 'Sie müssen mindestens ein Dokument auswählen',
      EmptyDeleteSelectionTitle: 'Dokumente löschen',
      Delete: 'Löschen',
      Download: 'Herunterladen',
      ShareTrack: 'Strecke teilen'
    },
    CustomTracks: {
      Title: 'Benutzerdefinierte Spuren',
      EditCustomTrack: 'Benutzerdefinierte Feldliste bearbeiten',
      EditSuccessMessage: 'Die benutzerdefinierte Feldliste wurde erfolgreich geändert.',
      EditErrorMessage: 'Beim Ändern der benutzerdefinierten Feldliste ist ein Fehler aufgetreten.',
      VisualiseCustomTrack: 'Anzeige der benutzerdefinierten Feldliste',
      Table: {
        TrackName: 'Spurname',
        SaveDate: 'Speicherdatum',
        LastUseDate: 'Datum der letzten Nutzung',
        OwnerFullName: 'Name des Erstellers',
        ModuleOfOrigin: 'Herkunftsmodul'
      },
      DeleteDialog: {
        Body: 'Möchten Sie die benutzerdefinierte Feldliste löschen?',
        Body_plural: 'Möchten Sie benutzerdefinierte Feldlisten löschen?',
        Title: 'Benutzerdefinierte Feldliste löschen',
        Title_plural: 'Löschen Sie benutzerdefinierte Feldlisten',
        Subtitle: 'Bitte bestätigen Sie die Fortsetzung des Vorgangs.',
        Success: 'Die benutzerdefinierte Feldliste wurde erfolgreich gelöscht.',
        Success_plural: 'Benutzerdefinierte Feldlisten wurden erfolgreich gelöscht.',
        Error: 'Beim Löschen Ihrer benutzerdefinierten Feldliste ist ein Fehler aufgetreten.',
        Error_plural: 'Beim Löschen Ihrer benutzerdefinierten Feldlisten ist ein Fehler aufgetreten.'
      },
      RenameDialog: {
        Title: 'Ändern Sie den Namen der benutzerdefinierten Feldliste',
        Body: 'Bitte geben Sie einen Namen für die benutzerdefinierte Feldliste ein',
        NameInput: 'Name der benutzerdefinierten Feldliste',
        Success: 'Die benutzerdefinierte Feldliste wurde erfolgreich umbenannt.',
        Error: 'Beim Umbenennen der benutzerdefinierten Feldliste ist ein Fehler aufgetreten.'
      },
      EmptyDialog: {
        Title: 'Benutzerdefinierte Strecke löschen',
        Text: 'Sie müssen mindestens einen benutzerdefinierten Track auswählen.'
      },
      ShareCustomTrackErrorDialog: {
        Title: 'Benutzerdefinierte Feldliste teilen',
        Text: 'Beim Teilen der benutzerdefinierten Feldliste ist ein Fehler aufgetreten.'
      }
    }
  },
  Operations: {
    EmptyText: 'Hier finden Sie alle Abfragen, die im Bereich "Operatione" gespeichert sind.',
    Table: {
      ResumePortfolioQueryTooltip: 'Öffnen Sie die "Operatione" mit vorausgewählten Filtern aus {{queryName}}'
    }
  },
  Info: {
    User: 'Nutzer',
    LoginAs: 'Melden Sie sich als anderer Benutzer an',
    Level: 'Rolle',
    Office: 'Firma',
    Subscription: 'Abonnement',
    NameAndSurname: 'Name und Nachname',
    JobTitle: 'Berufsbezeichnung',
    Telephone: 'Telefon',
    CompanyName: 'Firmenname',
    Country: 'Land'
  },
  Account: {
    Password: {
      Password: 'Passwort',
      Current: 'derzeit:',
      ChangePasswordTitle: 'Passwort ändern',
      ChangePassword: 'Passwort ändern',
      EnterPassword: 'Bitte geben Sie das neue Passwort ein',
      OldPassword: 'Aktuelles Passwort',
      NewPassword: 'Neues Passwort',
      ConfirmPassword: 'Neues Password wiederholen'
    },
    Language: {
      Language: 'Sprache:',
      Current: 'derzeit',
      ChangeLanguageTitle: 'Sprache ändern',
      ChangeLanguage: 'Die Sprache der Inhalte ändern'
    },
    Currency: {
      Title: 'Währung',
      Current: 'Aktuell',
      ChangeCurrencyTitle: 'Währung ändern',
      ChangeCurrency: 'Ändern Sie die Währung des Inhalts'
    },
    Subscription: {
      Title: 'Mein Abonnement (Rings)',
      Available: 'Verfügbar',
      AvailableTooltip: 'Ringe, die auf dem für Ihr Konto verfügbaren Abonnement übrig sind',
      Used: 'Benutzt',
      UsedTooltip: 'Von Ihrem Konto ausgegebene Ringe',
      Total: 'Gesamt',
      TotalTooltip: 'Für Ihr Konto gekaufte Ringe',
      Validity: 'Gültigkeit',
      ValidityInfo: 'Von {{from}} bis {{to}}',
      CreditInfoWithoutCompanyName: ', um Ihren Vertrag zu aktualisieren oder weitere Rings zu kaufen',
      Error: 'Verbrauchsgrenzen konnten nicht geladen werden.',
      EsgError: 'Fehler beim Laden der ESG-Ringe, bitte versuchen Sie es später noch einmal.'
    },
    Contact: {
      Title: 'Kontakte',
      User: 'User',
      Email: 'Email',
      UpdateInfoWithoutCompanyName: ', um Ihre Kontaktdaten zu ändern'
    }
  },
  TargetingTab: {
    SavedQuery: 'Gespeicherte Abfrage',
    DownloadedDocuments: 'Heruntergeladene Dokumente'
  },
  QueryDetail: {
    VisualiseQuery: 'Abfrage visualisieren',
    Monitored: 'Verfolgen'
  },
  ReleaseNotes: {
    Title: 'Neuesten Nachrichten',
    NewNote: 'Neuer Veröffentlichungshinweis',
    NoteTitle: 'Titel',
    NoteDescription: 'Kurze Beschreibung',
    NoteContent: 'Inhalt',
    WhatsNew: "Was gibt's Neues",
    GoToNewsPage: 'Gehen Sie zur Nachrichtenseite, um zu sehen, was diesen Monat neu ist'
  },
  Configuration: {
    ConfigureMargoTitle: 'Margò Konfiguration',
    CreateOfficeConfiguration: 'Bürokonfiguration erstellen',
    CreateUserConfiguration: 'Benutzerkonfiguration erstellen',
    SearchOfficePlaceholder: 'Suchen Sie nach Office Name',
    SearchUserPlaceholder: 'Suchen Sie nach Benutzername',
    EmptyText: 'Hier finden Sie gespeicherte Konfigurationen.',
    ViewConfigurationTitle: 'Konfiguration für',
    AddConfiguration: {
      OfficeTitle: 'Wählen Sie ein oder mehrere Büros aus',
      OfficeDescription:
        'Wählen Sie ein oder mehrere Büros aus, für die Sie eine Konfiguration erstellen möchten.\nSie erstellen eine Bürokonfiguration, die für alle gegenwärtigen und zukünftigen Benutzer gilt. Sie können die Konfiguration jederzeit ändern.',
      UsersTitle: 'Wählen Sie einen oder mehrere Benutzer aus',
      UsersDescription:
        'Wählen Sie einen oder mehrere Benutzer aus, für die Sie eine Konfiguration erstellen möchten.\nWenn der Benutzer zu einem konfigurierten Büro gehört, überschreibt die Benutzerkonfiguration die Bürokonfiguration.',
      ParametersTitle: 'Parameter auswählen',
      ParametersDescription:
        'Wählen Sie die Parameter aus, nach denen Unternehmen gefiltert werden.\nWenn Sie einen Benutzer ausgewählt haben, der zu einem konfigurierten Büro gehört, zeigt die folgende Liste den vom Büro angewendeten Filter.\nSie können die Office-Konfiguration nur für ausgewählte Benutzer überschreiben und alle Filter bearbeiten oder löschen.',
      FullMargoLabel: 'Alle Filter deaktivieren und Margo vollständig anzeigen',
      ConfigurationSuccessful: 'Konfiguration war erfolgreich',
      ConfigurationSuccessfulDescription: 'Sie können die Konfiguration jederzeit über Ihr Konto ändern'
    },
    DeleteConfiguration: {
      Title: 'Konfiguration löschen',
      Subtitle: 'Möchten Sie die Konfiguration wirklich löschen?',
      Body: 'Die Benutzersichtbarkeit ist nicht mehr auf die während der Konfiguration ausgewählten Parameter beschränkt.',
      Success: 'Konfiguration gelöscht',
      Error: 'There was error while deleting configuration.'
    },
    Table: {
      UserName: 'Nutzername',
      OfficeName: 'Büroname',
      Creator: 'Ersteller der Konfiguration',
      UpdatedAt: 'Datum des letzten Updates'
    },
    Offices: 'Büros',
    Users: 'Benutzer',
    UserTabTitle: 'Benutzer',
    OfficeTabTitle: 'Büro',
    AppliedFilterTabTitle: 'Angewandte Filter',
    AlreadyConfigured: 'Bereits konfiguriert',
    FullMargoLabelForView: 'Kein Filter angewendet: Der Benutzer sieht die vollständige Margo'
  },
  Esg: {
    Title: 'ESG entsperrt',
    UnlockedSubtitle: 'Spielstände freigeschaltet',
    ContactToUpdate: ', um Ihren Zahlungsplan zu aktualisieren'
  }
}
