// eslint-disable-next-line import/no-default-export
export default {
  PageTitle: "Inserisci un contatto per un'azienda",
  SaveAddAnotherContact: 'Salva e aggiungi nuovo contatto',
  CompanyIdentificationIta: 'Codice fiscale/Partita IVA',
  CompanyIdentificationGer: 'Numero ONR/Partita IVA',
  CompanyIdentificationSui: 'Identificativo Azienda/Partita IVA',
  CompanyIdentificationAt: 'Company book number/Partita IVA',
  CompanyIdentificationSuiAt: 'Identificativo Azienda/Company book number/Partita IVA',
  ClassificationIta: 'Ateco primario (2007)',
  ClassificationGer: 'Classificazione WZ2008',
  ClassificationSui: 'NOGA',
  ClassificationAt: 'OENACE 2008',
  ClassificationSuiAt: 'NOGA/OENACE 2008',
  CompanyCity: "Città dell'Azienda",
  Contact: 'Contatto',
  JobTitle: 'Titolo di lavoro',
  Office: 'Ufficio',
  CallAllowed: 'Chiamata consentita',
  EmailOptOut: 'Esclusione email',
  LeadQualification: 'Qualifica della Lead',
  ExpandTooltip: 'Clicca per espandere questa sezione',
  Challenges: 'Obiettivi professionali',
  DecisionMaker: "Decisore/Ha un'influenza",
  MoneyBudget: 'Ha disponibilità di spesa?',
  Priority: 'Priorità',
  Notes: 'Note',
  DisabledTooltip: "Seleziona prima l'azienda",
  CreateErrorTitle: 'Errore durante la creazione del contatto',
  CreateErrorDescription: "Riprova più tardi o contatta l'assistenza clienti",
  CreateSuccessTitle: 'Contatto creato con successo',
  CreateSuccessDescription: 'Sarai reindirizzato alla pagina precedente',
  ChallengesPlaceholder: 'Quali sono le esigenze del contatto?',
  PriorityPlaceholder: "Entro il primo trimestre del 2024, L'anno prossimo...",
  SearchCompanyPlaceholder: "Cerca l'azienda per nome",
  SearchByIdPlaceholder: "Cerca l'azienda per ",
  CompanyRequired: 'Azienda è richiesta',
  CountryRequired: 'Paese è richiesto',
  InsertNewContact: 'Inserisci un nuovo contatto',
  DownloadList: 'Scarica Lista',
  SearchBy: 'Trova contatto da',
  ByCompanyName: 'nome azienda',
  ByContactInformation: 'informazioni di contatto',
  ByContactPlaceholder: 'Trova il contatto per Nome, Cognome, Titolo di lavoro o Email',
  ByCompanyPlaceholder: 'Trova contatto per nome azienda',
  NameAndSurname: 'Nome e cognome',
  PostedBy: 'Pubblicato da',
  DeleteContact: {
    Title: 'Cancella contatto',
    Subtitle: 'Cancellare permanentemente le informazioni di contatto?',
    Body: 'Non sarà più possibile recuperarle e sarà necessario reinserirle manualmente.',
    Success: 'Il contatto è stato eliminato con successo.',
    Error: "C'è stato un errore durante l'eliminazione del contatto."
  },
  UpdateSuccessTitle: 'Contatto aggiornato con successo',
  UpdateErrorTitle: "Errore durante l'aggiornamento del contatto",
  EditNoteTitle: 'Modifica nota',
  NoteUpdated: 'Nota aggiornata con successo',
  NoteUpdateError: "Errore durante l'aggiornamento della nota",
  CompanyReport: {
    ManageContacts: 'Gestisci contatti',
    EmptyText: 'Qui troverai tutti i tuoi contatti per questa azienda.'
  },
  WidgetInputPlaceholder: 'Trova contatti per nome, cognome, email o titolo di lavoro',
  EditTooltip: 'Modifica contatto',
  DeleteTooltip: 'Cancella contatto',
  AddContact: 'Aggiungi contatto'
}
