// eslint-disable-next-line import/no-default-export
export default {
  PageTitle: 'Entrez un contact pour une entreprise',
  SaveAddAnotherContact: 'Enregistrer et ajouter un nouveau contact',
  CompanyIdentificationIta: 'Code fiscal/N° de TVA',
  CompanyIdentificationGer: 'Numéro ONR/N° de TVA',
  CompanyIdentificationSui: "Numéro d'identification de l'entreprise/N° de TVA",
  CompanyIdentificationAt: "Numéro d'identification de l'entreprise/Company book number/N° de TVA",
  CompanyIdentificationSuiAt: "Numéro d'identification de l'entreprise/Company book number/N° de TVA",
  ClassificationIta: 'Ateco principal (2007)',
  ClassificationGer: 'Classification WZ2008',
  ClassificationSui: 'NOGA',
  ClassificationAt: 'OENACE 2008',
  ClassificationSuiAt: 'NOGA/OENACE 2008',
  CompanyCity: "Ville de l'Entreprise",
  Contact: 'Contact',
  JobTitle: "Titre de l'emploi",
  Office: 'Bureau',
  CallAllowed: 'Appel autorisé',
  EmailOptOut: 'Désabonnement email',
  LeadQualification: 'Qualification du responsable',
  ExpandTooltip: 'Cliquez pour déplier cette section',
  Challenges: 'Défis',
  DecisionMaker: 'Décideur/A une influence',
  MoneyBudget: 'Y a-t-il une disponibilité de dépenses?',
  Priority: 'Priorité',
  Notes: 'Notes',
  DisabledTooltip: "Sélectionnez d'abord une entreprise",
  CreateErrorTitle: 'Erreur lors de la création du contact',
  CreateErrorDescription: 'Veuillez réessayer ultérieurement ou contacter le support client',
  CreateSuccessTitle: 'Contact créé avec succès',
  CreateSuccessDescription: 'Vous serez redirigé vers la page précédente',
  ChallengesPlaceholder: 'Quels sont les besoins du contact ?',
  PriorityPlaceholder: "Au premier trimestre 2024, l'année prochaine...",
  SearchCompanyPlaceholder: "Recherchez l'entreprise par nom",
  SearchByIdPlaceholder: "Recherchez l'entreprise par ",
  CompanyRequired: "L'entreprise est requise",
  CountryRequired: 'Le pays est requis',
  InsertNewContact: 'Insérer un nouveau contact',
  DownloadList: 'Télécharger la liste',
  SearchBy: 'Rechercher un contact par',
  ByCompanyName: "Nom de l'entreprise",
  ByContactInformation: 'informations de contact',
  ByContactPlaceholder: 'Rechercher un contact par nom, prénom, fonction ou e-mail',
  ByCompanyPlaceholder: "Trouver un contact par nom d'entreprise",
  NameAndSurname: 'Nom et prénom',
  PostedBy: 'Publié par',
  DeleteContact: {
    Title: 'Effacer le contact',
    Subtitle: 'Supprimer définitivement les informations de contact?',
    Body: 'Il ne sera plus possible de les récupérer et vous devrez les ressaisir manuellement.',
    Success: 'Le contact a été supprimé avec succès.',
    Error: "Une erreur s'est produite lors de la suppression du contact."
  },
  UpdateSuccessTitle: 'Contact mis à jour avec succès',
  UpdateErrorTitle: 'Erreur lors de la mise à jour du contact',
  EditNoteTitle: 'Modifier la note',
  NoteUpdated: 'Note mise à jour avec succès',
  NoteUpdateError: 'Erreur lors de la mise à jour de la note',
  CompanyReport: {
    ManageContacts: 'Gérer les contacts',
    EmptyText: 'Ici, vous trouverez tous vos contacts pour cette entreprise.'
  },
  WidgetInputPlaceholder: 'Trouver des contacts par nom, prénom, email ou titre de poste',
  EditTooltip: 'Modifier le contact',
  DeleteTooltip: 'Supprimer le contact',
  AddContact: 'Ajouter un contact'
}
