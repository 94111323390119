/* eslint-disable import/no-default-export */
/* eslint-disable camelcase */
export default {
  SalesTool: {
    Header: 'Gestione vendite',
    Management: {
      Assignee: 'Assegnatario',
      AssigneeSgCzsk: 'Assegnatario',
      Campaigns: 'Campagne',
      NewCampaign: 'Nuova Campagna',
      NewCampaignTooltip: 'Pianifica una campagna commerciale a partire da un portafoglio',
      CampaignArchive: 'Archivio campagne',
      CampaignArchiveTooltip: "Accedi all'archivio delle campagne completate e rinnovale",
      CampaignsToAssign: 'Da assegnare',
      CampaignsToStart: 'Non avviate',
      SearchCampaign: 'Cerca campagna',
      SearchCampaignLong: 'Cerca per nome campagna',
      CampaignsActive: 'Avviate',
      CampaignsArchived: 'Archiviate',
      Stats: {
        Companies: 'Impresa',
        Companies_plural: 'Imprese',
        StartDate: 'Data inizio',
        EndDate: 'Data fine',
        EnrichedCompanies: 'Imprese arricchite',
        Clients: 'Clienti',
        Suppliers: 'Fornitori',
        Prospects: 'Prospect',
        LastUpdate: 'Data di aggiornamento',
        Competitors: 'Competitors',
        Lead: 'Lead'
      },
      Table: {
        NumberOfCompanies: 'Numero di imprese',
        GoalName: "Nome dell'Obiettivo",
        Creator: 'Creatore',
        Tasks: 'Attività',
        Status: 'Stato',
        CampaignPortfolios: 'Portafogli in questa campagna'
      },
      PorfolioBox: 'Portafogli in questa campagna',
      ConversionRate: '{{percentage}}% tasso di conversione',
      CompletedTasks: '{{percentage}}% incarichi completati',
      Banner: {
        Title: 'Clicca sul nome della campagna per assegnare Prodotti e Venditori.',
        Subtitle: 'Prima di iniziare la campagna assegna Venditori e Prodotti a ogni impresa nella lista.'
      },
      CreatedAtAscending: 'Data di creazione (Ascendente)',
      CreatedAtDescending: 'Data di creazione (Discendente)',
      StartDateAscending: 'Data di inizio (Ascendente)',
      StartDateDescending: 'Data di inizio (Discendente)',
      EndDateAscending: 'Data di fine (Ascendente)',
      EndDateDescending: 'Data di fine (Discendente)'
    },
    AddCampaign: {
      EnterName: 'Inserisci un nome rappresentativo per la campagna',
      EnterNameDescription: 'Verrà utilizzato un ring per ogni campagna creata',
      EnterDates: 'Seleziona data inizio e data fine',
      withoutCustomVariables: 'senza variabili custom',
      withCustomVariables: 'con variabili custom',
      EnterDatesDescription:
        'Le date di inizio e fine verranno sempre visualizzate associate alla campagna e aiuteranno te e le tue vendite a raggiungere gli obiettivi aziendali',
      EnterPortfolio: 'Scegli il portafoglio che desideri associare alla campagna',
      EnterPortfolioDescription: 'Associa uno o più portafoglio alla campagna che vuoi creare.',
      EnterGoal: 'Scegli un obiettivo per la campagna',
      EnterGoalDescription: 'Seleziona un obiettivo associato ad alcuni incarichi.',
      CreateGoalDescription: 'Clicca qui per creare un nuovo <0>Obiettivo</0>.',
      InvalidGoal:
        "L'obiettivo selezionato non presenta nessuna attività. Si prega di seleziona un altro obiettivo o cliccare qui per creare un nuovo <0>Obiettivo</0>",
      EnterConversionRate: 'Inserisci una descrizione e imposta il tasso di conversione',
      EnterConversionRateDescription:
        "Inserisci una descrizione della campagna che stai creando, fornirà ulteriori informazioni sull'obiettivo che desideri raggiungere.",
      CampaignCreationPendingTitle: 'Creazione campagna',
      CampaignCreationPendingText:
        "La creazione della campagna è in corso. Verrai reindirizzato alla pagina iniziale del Gestione Vendite e riceverai una notifica non appena l'operazione sarà conclusa.",
      SalesPerformanceChartTitle: 'Rendere visibile il grafico "Andamento vendite" anche per gli utenti Sales?',
      SalesPerformanceChartText: 'Abilita il grafico',
      RestoreDataQuestion: 'Riabilitare tutti i dati associati a questa campagna a prima della sua scadenza?',
      RestoreDataEnable: 'Riabilita tutti i dati precedenti',
      RestoreDataEnableDisabledTooltip:
        "L'obiettivo originario di questa campagna non può essere selezionato in quanto cancellato. Seleziona un nuovo obiettivo fra quelli disponibili.",
      AssignedInformation:
        'Nota che in caso un’azienda sia stata assegnata sia a un venditore che a un manager, nella Colonna assegnatario della campagna sarà riportato il nome del venditore'
    }
  },
  ManageCampaign: {
    Header: 'Gestisci vendite e prodotti',
    SalesHeader: 'Gestisci Campagna'
  },
  Management: {
    Message: {
      Header: 'Crea una campagna, assegnala alle tue vendite e avviala.',
      SubHeader: 'Per creare una campagna usa il pulsante "Crea campagna".',
      Description:
        'Dopo aver creato la tua campagna puoi assegnare le imprese alle vendite e quindi avviarla. In questa pagina troverai tutte le tue campagne.'
    },
    Campaign: {
      ChangeCampaignName: 'Cambia il nome della campagna',
      ChangeCampaignNameShort: 'Cambia il nome',
      ChangeCampaignNote: 'Cambia la nota della campagna',
      AddCampaignNote: 'Aggiungi nota',
      ChangeCampaignNoteDescription: 'Modifica la nota per descrivere il tasso di conversione.',
      AddCampaignNoteDescription: 'Inserisci una nota per descrivere il tasso di conversione.',
      EnterARepresentativeName: 'Inserisci un nome rappresentativo per la campagna',
      InsertCampaignName: 'Inserisci il nome della campagna',
      InsertCampaignNote: 'Inserisci una nota',
      NameChangedCorrectly: 'Il nome è stato modificato correttamente in',
      NoteChangedCorrectly: 'La nota è stata modificata correttamente',
      NoteRemovedCorrectly: 'La nota è stata eliminata correttamente',
      NoteAddedCorrectly: 'Nota aggiunta correttamente',
      ChangeEndDate: 'Modifica la data di fine',
      ChangeEndDateDescription: 'Modifica la data di fine corrente.',
      ChangeEndDateSuccess: 'finirà il',
      ChangeStartDate: 'Modifica la data di inizio',
      ChangeStartDateDescription: 'Cambia la data di inizio corrente.',
      ChangeStartDateSuccess: 'inizierà in data',
      StartCampaignDialogText1: 'Vuoi avviare la campagna?',
      StartCampaignDialogText2:
        "I progressi dell'intera campagna saranno visibili nel pannello di controllo della Gestione vendite.",
      StartCampaignDialogText3: 'Cliccando su "continua", ogni Sales riceverà una notifica sulle sue assegnazioni.',
      StartCampaignDoneDialogText1: '{{campaignName}} inizia il {{startDate}}',
      StartCampaignDoneDialogText2: 'Ogni Sales riceverà una notifica sulle sue assegnazioni.',
      StopCampaignDialogText1: 'Vuoi terminare la campagna?',
      StopCampaignDialogText2: "La troverai nell'Archivio campagne, dove potrai rinnovarla quando vorrai.",
      StopCampaignDialogText3: "Per vedere l'Archivio campagne, torna alla pagina iniziale della Gestione vendite.",
      StopCampaignDoneDialogText1:
        "{{campaignName}} è stata terminata correttamente, la troverai nell'Archivio campagne.",
      DeleteCampaignDialogText1: 'Vuoi davvero eliminare {{campaignName}}?',
      DeleteCampaignDialogText2: 'Tutti i risultati della campagna saranno eliminati.',
      DeleteCampaignDoneDialogText1: '{{campaignName}} è stata eliminata correttamente.'
    }
  },
  AssignCampaign: {
    Header: 'Assegna vendite e prodotto',
    CreatedBy: 'Creata da {{ownerName}}',
    StartDate: 'Data inizio:',
    EndDate: 'Data fine:',
    AddPortfolio: 'Aggiungi imprese/portafoglio',
    AddPortfolioTooltip: 'Aggiungi nuove imprese a questa campagna',
    SelectColumns: 'Seleziona colonne',
    GroupActions: 'Azioni di gruppo',
    SalestoolAssignmentsSelectPlaceholder: 'Seleziona',
    AssignSales: 'Assegna sales',
    AssignProduct: 'Assegna prodotto',
    NotAssigned: 'Non assegnato',
    Sales: 'Sales',
    Product: 'Prodotto',
    Note: 'Note',
    EditNoteIconTitle: 'Modifica note',
    CompaniesCount: 'Impresa',
    CompaniesCount_plural: 'Imprese',
    AssignedCount: 'Assegnate',
    NotAssignedCount: 'Non assegnate',
    AppAround: {
      NotAlreadyDone: 'Nessuna offerta è stata ancora inviata',
      InProgress: "Un'offerta è già stata inviata il {{dateTime}}",
      Ko: 'Offerta in errore il {{dateTime}}',
      Ok: 'Offerta inviata il {{dateTime}}'
    },
    Inventia: {
      NotAlreadyDone: 'Nessuna chiamata è stata avviata',
      InProgress: 'La chiamata è stata avviata il {{dateTime}}',
      Ko: 'Errore nella chiamata, il {{dateTime}}',
      Ok: 'Chiamata effettuata il {{dateTime}}'
    },
    Banner: {
      Title: 'Clicca su "Assegna campagna" per assegnare Prodotti e Venditori',
      Subtitle:
        'Prima di iniziare la campagna assegna Venditori e Prodotti a ogni impresa nella lista. Al termine clicca su "Salva" per completare l\'operazione, la campagna non sarà avviata finché non clicchi su "Avvia campagna".'
    },
    WriteYourNote: 'Scrivi qui le tue note',
    Dialog: {
      SelectAtLeastOneCompany: "Per proseguire è necessario selezionare almeno un'impresa",
      AssignSaleTitle: 'Assegna venditore',
      AssignProductTitle: 'Assegna prodotto',
      AssignSaleOrProductMultiple: '{{item}} assegnato a {{count}} impresa.',
      AssignSaleOrProductMultiple_plural: '{{item}} assegnato a {{count}} imprese.',
      DuplicateCompanyTitle: 'Duplica impresa',
      DuplicateCompanyConfirm: 'Confermi di voler duplicare {{companyName}}?',
      DuplicateCompanySelection: "Per duplicare un'impresa è necessario cambiare il suo stato o prodotto",
      DuplicateCompanyDone: "{{companyName}} duplicata correttamente all'interno di {{campaignName}}.",
      DuplicateCompanySelectProduct: 'Seleziona prodotto',
      DuplicateCompanySelectStatus: 'Seleziona stato',
      DuplicateCompanyProduct: 'Prodotto',
      DuplicateCompanyStatus: 'Stato',
      SaveCampaignTitle: 'Salva campagna',
      SaveCampaignPending:
        "L'operazione di salvataggio è in corso e potrebbe richiedere alcuni minuti. Riceverai una notifica al termine dell'operazione.",
      AddCompaniesTitle: 'Aggiungi imprese',
      AddPortfolioTitle: 'Aggiungi imprese/portafoglio',
      AddPortfolio: 'Seleziona il portafoglio che vuoi aggiungere',
      AddPortfolioSelectPortfolio: 'Seleziona un portafoglio',
      AddCompaniesPending:
        "Aggiunta imprese a {{campaignName}} in corso. Al termine dell'operazione, riceverai una notifica.",
      AddCompaniesWithCustomVariablesStructureChangedPending:
        "Aggiunta di imprese alla campagna {{campaignName}} in corso. Alcune Variabili Custom non saranno inserite per incompatibilità nella struttura delle variabili. Al termine dell'operazione, riceverai una notifica.",
      AddPortfolioPending:
        "Aggiunta di {{selectedPortfolioName}} a {{campaignName}} in corso. Al termine dell'operazione, riceverai una notifica.",
      AddCompanyNoteTitle: 'Note',
      AddCompanyNote: 'Puoi aggiungere fino a cinque note a questa impresa',
      AddCompanyNoteTextAreaLabel: 'Inserisci una nuova nota (max. 250 caratteri)',
      AddCompanyNoteTextAreaPlaceholder: 'La tua nota...',
      AddNewCompaniesFromStartingPortfolio: 'Aggiungi nuove imprese dal portafoglio originario',
      AddedCompanies: 'Imprese aggiunte',
      AddedPortfolio: 'Portafoglio aggiunto',
      AddPortfolioSelect: 'Seleziona',
      DeleteNoteConfirmation: 'Confermi di voler rimuovere questa nota?',
      DeleteCompany: {
        Title: 'Rimuovi impresa',
        Text: "Sei sicuro di voler rimuovere l'impresa dalla campagna?",
        Success: "L'impresa è stata correttamente eliminata dalla campagna.",
        Error: "Si è verificato un errore durante l'eliminazione dell'impresa dalla campagna."
      },
      MoveCompany: {
        Title: 'Sposta impresa in altra campagna',
        Text: "L'impresa che hai selezionato verrà rimossa dalla campagna attuale e aggiunta in un'altra campagna. Seleziona una campagna dalla lista.",
        SelectLabel: 'Seleziona campagna',
        Footer: "Sales e prodotti assegnati all'impresa saranno eliminati",
        Success: "L'impresa è stata spostata correttamente nella campagna.",
        Error: "Si è verificato un errore durante lo spostamento dell'impresa nella campagna",
        ErrorIncompatibleDestEntity:
          'La struttura delle Variabili Custom di questa campagna non è compatibile con quella del portafoglio di origine.'
      }
    },
    Errors: {
      DuplicateCampaign: "Si è verificato un errore durante la duplicazione dell'impresa.",
      DuplicateCampaignMissingPortfolio:
        "Impossibile duplicare l'impresa in quanto il portafoglio a cui appartiene non esiste più.",
      AddPortfolio: "Si è verificato un errore durante l'aggiunta del portafoglio.",
      AddCompanies: "Si è verificato un errore durante l'aggiunta di nuove imprese dal portafoglio originario."
    }
  },
  SalesPerformance: {
    Banner: {
      Title: 'Le tue performance di vendita',
      Subtitle: 'per ogni singolo incarico saranno visibili in questo box'
    },
    Tooltip: "Questo box mostra i migliori 5 Assegnatari in relazione all'incarico selezionato",
    Actions: 'Azioni',
    TotalProgressOrder: 'Progresso totale',
    LastUpdateOrder: 'Ultimo aggiornamento',
    NumberCompaniesOrder: 'Numero di imprese assegnate'
  },
  CampaignDetail: {
    MapBoxTitle: 'Distribuzione geografica',
    CompaniesBoxTitle: 'Imprese per incarico',
    AssigneeBoxTitle: 'Andamento assegnatario',
    NoteLabel: 'Nota',
    Task: 'Incarico',
    TasksStatisticsLabel: 'impresa',
    TasksStatisticsLabel_plural: 'imprese',
    AssignTask: 'Assegna incarico',
    SalesPerformanceNCompanies: 'N° di imprese',
    WaterfallChartTasksCompleted: 'completati',
    WaterfallChartBoxTitle: '{{count}} impresa {{percentage}}% completato',
    WaterfallChartBoxTitle_plural: '{{count}} imprese {{percentage}}% completato',
    AllCompaniesLabel: 'Tutte',
    AssignedCompaniesLabel: 'Assegnate',
    UnassignedCompaniesLabel: 'Non assegnate',
    Dialog: {
      AssignTaskTitle: 'Assegnazione incarico',
      CampaignReceived: {
        Title: 'Campagna ricevuta',
        Text: 'Nota che hai ricevuto questa campagna da {{ownerName}}.',
        SubText: 'Potrai modificare i task solo per le aziende che ti sono state assegnate.'
      }
    },
    ShowAssigneeDetail: 'Mostra dettagli sul Assegnatario'
  },
  CompaniesOnTask: {
    Void: 'Void',
    Called: 'Chiamate',
    Visited: 'Visitate',
    Negotiation: 'Trattativa',
    Active: 'Attive',
    Negative: 'Negative'
  },
  CompanyDetail: {
    Actions: {
      DownloadPDF: 'Scarica PDF',
      AddToCampaign: 'Aggiungi alla campagna'
    }
  },
  CampaignArchive: {
    Subtitle:
      'Contiene le campagne interrotte e terminate. Clicca su "Rinnova campagna" per riniziare la campagna mantenendo le assegnazioni precedenti.',
    SubtitleSale: 'Contiene le campagne interrotte e terminate.',
    RenewCampaign: 'Rinnova campagna',
    DeleteCampaign: 'Elimina'
  },
  EnrichBeforeRenew: 'Per iniziare la campagna, arricchire prima i seguenti portafogli:',
  SaleDetail: {
    Area: 'Area',
    CampaignsView: 'Lista campagne',
    Summary: 'Riepilogo',
    TotalCompanies: 'Imprese totali',
    TotalCampaigns: 'Campagne totali',
    ActiveCampaigns: 'Campagne attive',
    ArchivedCampaigns: 'Campagne archiviate',
    CompletedCampaigns: 'Campagne completate',
    AssignTaskDialogText1: "Vuoi cambiare l'incarico?",
    AssignTaskDialogText1_noPrevTask: "Vuoi assegnare l'incarico?",
    AssignTaskDialogText2: 'Stai per cambiare l\'incarico da "{{originalTask}}" a "{{newTask}}".',
    AssignTaskDialogText2_noPrevTask: 'Stai per assegnare "{{newTask}}".',
    AssignTaskDoneDialogText1: "L'incarico è stato cambiato correttamente!",
    AssignTaskDoneDialogText1_noPrevTask: "L'incarico è stato assegnato correttamente!",
    AssignTaskDoneDialogText2: 'Il tuo manager riceverà una notifica.',
    AssignTaskMultipleDoneDialogText1: "L'assegnazione degli incarichi selezionati è in corso.",
    AssignTaskMultipleDoneDialogText2: 'Al termine, il tuo manager riceverà una notifica.',
    AssignTasksDialogText: 'Stai per assegnare "{{newTask}}" a {{fCount}} impresa.',
    AssignTasksDialogText_plural: 'Stai per assegnare "{{newTask}}" a {{fCount}} imprese.',
    AssignTasksNotOwnerInfo: 'Saranno cambiate solo le aziende che ti sono state assegnate.'
  },
  ItemNotAssignedYet: 'Non ancora assegnato',
  LoadMore: 'Carica altro',
  DownloadBtn: 'Download PDF/Excel',
  DownloadBtnTooltip: 'Scarica un report di questa campagna sul tuo dispositivo',
  AssignCampaignBtn: 'Assegna campagna',
  AssignCampaignBtnTooltip: 'Assegna sales e prodotti alle imprese in questa campagna',
  ManageCampaignBtn: 'Gestisci campagna',
  ManageCampaignBtnTooltip: 'Assegna sales e prodotti alle imprese in questa campagna',
  StartCampaignBtn: 'Avvia campagna',
  StartCampaignBtnTooltip: 'Abilita la visibilità della campagna ai sales e monitora le performance',
  StopCampaignBtn: 'Termina campagna',
  StopCampaignBtnTooltip: 'Concludi questa campagna ed archiviala',
  DeleteCampaign: 'Elimina campagna',
  CampaignName: 'Nome della campagna',
  StartDate: 'Data di inizio',
  EndDate: 'Data di fine',
  Name: 'Nome',
  PortfolioLabel: 'Portafoglio',
  GoalLabel: 'Scegli un obiettivo',
  ConversionRateLabel: 'Definisci il tasso di conversione',
  Close: 'Chiudi',
  Save: 'Salva',
  SaveTooltip: 'Salva i cambiamenti apportati a questa campagna',
  CreateCampaign: 'Crea campagna',
  RenewCampaign: 'Rinnova campagna',
  DomainFilter: {
    SaveAndClose: 'Salva e Chiudi'
  },
  Table: {
    Empty: 'Nessuna impresa corrispondente ai tuoi criteri di ricerca'
  },
  Errors: {
    CampaignSave: 'Si è verificato un errore durante la creazione della campagna.'
  },
  GenericErrorTitle: 'Si è verificato un errore',
  Download: {
    PDFHeaderTitle: 'Report Campagna',
    DownloadTitle: 'Download PDF/Excel',
    DownloadPDF: 'Scarica PDF',
    DownloadExcel: 'Scarica Excel',
    SelectTypeOfList: 'Download PDF/Excel',
    SelectTypeOfListDescription:
      'Puoi scaricare il report completo della campagna in due formati, seleziona il tipo di formato che desideri scaricare.',
    CompaniesInCampaign: 'Imprese in questa campagna',
    AssignedCompanies: 'Imprese assegnate',
    ActiveCompanies: 'Imprese divenute "Attive"',
    Pdf: 'PDF',
    PdfDescription: 'Scarica in formato .PDF',
    Excel: 'Excel',
    ExcelDescription: 'Scarica in formato .XlS',
    DownloadExcelTitle:
      'Stai per scaricare un file contenente tutti i dati {{companyName}} e il risultato di questa campagna come lista.',
    DownloadExcelDescription:
      'Appena il documento Excel verrà creato riceverai una notifica, e potrai scaricarlo e salvarlo.',
    DownloadPDFPleaseWait: 'Per favore attendi mentre il PDF viene generato',
    DownloadExcelPendingMessage:
      "Il file richiesto è in elaborazione e potrebbe richiedere alcuni minuti.\nPuoi seguire l'avanzamento dal menu notifiche."
  },
  Actions: {
    GoToCampaign: 'Vai alla campagna'
  },
  CampaignStatus: {
    ToAssign: 'Da assegnare',
    ToStart: 'Non avviate',
    Active: 'Avviate',
    Stopped: 'Terminata',
    Expired: 'Scaduta'
  },
  EmptyCampaigns: 'Nessuna campagna trovata'
}
