// eslint-disable-next-line import/no-default-export
export default {
  PageTitle: 'Geben Sie einen Kontakt für ein Unternehmen ein',
  SaveAddAnotherContact: 'Speichern und neuen Kontakt hinzufügen',
  CompanyIdentificationIta: 'Tax code/MWST-Nr.',
  CompanyIdentificationGer: 'ONR-Nummer/MWST-Nr.',
  CompanyIdentificationSui: 'Umsatzsteuer-Identifikationsnummer/MWST-Nr.',
  CompanyIdentificationAt: 'Firmenbuchnummer/MWST-Nr.',
  CompanyIdentificationSuiAt: 'Umsatzsteuer-Identifikationsnummer/Firmenbuchnummer/UID-Nummer/MWST-Nr.',
  ClassificationIta: 'Primärer Ateco (2007)',
  ClassificationGer: 'WZ2008-Klassifikation',
  ClassificationSui: 'NOGA',
  ClassificationAt: 'OENACE 2008',
  ClassificationSuiAt: 'NOGA/OENACE 2008',
  CompanyCity: 'Firmenstadt',
  Contact: 'Kontakt',
  JobTitle: 'Berufsbezeichnung',
  Office: 'Büro',
  CallAllowed: 'Anruf erlaubt',
  EmailOptOut: 'Email-Abmeldung',
  LeadQualification: 'Lead-Qualifizierung',
  ExpandTooltip: 'Klicken, um diesen Abschnitt zu erweitern',
  Challenges: 'Herausforderungen',
  DecisionMaker: 'Entscheidungsträger/Hat einen Einfluss',
  MoneyBudget: 'Gibt es Ausgabemöglichkeiten?',
  Priority: 'Priorität',
  Notes: 'Notizen',
  DisabledTooltip: 'Wählen Sie zuerst ein Unternehmen aus',
  CreateErrorTitle: 'Fehler beim Erstellen des Kontakts',
  CreateErrorDescription: 'Bitte versuchen Sie es später erneut oder wenden Sie sich an den Kundensupport',
  CreateSuccessTitle: 'Kontakt erfolgreich erstellt',
  CreateSuccessDescription: 'Sie werden zur vorherigen Seite weitergeleitet',
  ChallengesPlaceholder: 'Was sind die Bedürfnisse des Kontakts?',
  PriorityPlaceholder: 'Im 1. Quartal 2024, nächstes Jahr...',
  SearchCompanyPlaceholder: 'Suchen Sie das Unternehmen nach Namen',
  SearchByIdPlaceholder: 'Suchen Sie das Unternehmen nach ',
  CompanyRequired: 'Unternehmen ist erforderlich',
  CountryRequired: 'Land ist erforderlich',
  InsertNewContact: 'Neuen Kontakt einfügen',
  DownloadList: 'Liste herunterladen',
  SearchBy: 'Suche Kontakt nach',
  ByCompanyName: 'Firmenname',
  ByContactInformation: 'Kontaktinformationen',
  ByContactPlaceholder: 'Finden Sie den Kontakt nach Name, Nachname, Berufsbezeichnung oder E-Mail',
  ByCompanyPlaceholder: 'Finde Kontakt nach firmenname',
  NameAndSurname: 'Name und Nachname',
  PostedBy: 'Veröffentlicht von',
  DeleteContact: {
    Title: 'Kontakt löschen',
    Subtitle: 'Kontaktinformationen dauerhaft löschen?',
    Body: 'Sie können dann nicht mehr wiederhergestellt werden und müssen manuell neu eingegeben werden',
    Success: 'Der Kontakt wurde erfolgreich gelöscht.',
    Error: 'Beim Löschen des Kontakts ist ein Fehler aufgetreten.'
  },
  UpdateSuccessTitle: 'Kontakt erfolgreich aktualisiert',
  UpdateErrorTitle: 'Fehler beim Aktualisieren des Kontakts',
  EditNoteTitle: 'Notiz bearbeiten',
  NoteUpdated: 'Notiz erfolgreich aktualisiert',
  NoteUpdateError: 'Fehler beim Aktualisieren der Notiz',
  CompanyReport: {
    ManageContacts: 'Kontakte verwalten',
    EmptyText: 'Hier finden Sie alle Ihre Kontakte für dieses Unternehmen.'
  },
  WidgetInputPlaceholder: 'Finde Kontakte nach Namen, Nachnamen, E-Mail oder Berufsbezeichnung',
  EditTooltip: 'Kontakt bearbeiten',
  DeleteTooltip: 'Kontakt löschen',
  AddContact: 'Kontakt hinzufügen'
}
