import { MAX_FILE_NAME_LENGTH } from '../../pages/CompanySurveys/constants'

/* eslint-disable import/no-default-export */
export default {
  MainTitle: 'Company Name',
  Actions: 'Actions',
  DownloadPDF: 'Download PDF',
  AddToCampaign: ' Add to Campaign',
  CompanyData: {
    CompanyName: 'Company Name',
    CompanyId: 'Company Id',
    Title: 'Preview',
    Town: 'Town',
    OfficeClassification: 'Office Type',
    Ateco2007: 'Primary Ateco (2007)',
    NaceCode: 'Prevailing activity (NACE)',
    LegalForm: 'Legal form',
    LastUpdate: 'Last Update',
    TaxCode: 'Tax code',
    TaxNumber: 'Tax number',
    VatCode: 'Vat code',
    VatNumber: 'VAT number',
    ZipCity: 'Zip code, City',
    OnrNumber: 'ONR Number',
    MainIndustrialSectors: 'Main industrial sectors',
    IdentificationNumber: 'Business identification number',
    CommercialDisplay: 'Commercial Display'
  },
  CompanySummary: {
    Evaluation: 'Assessment',
    Profile: 'Profile',
    AffidabilityScore: 'Affidability Score',
    CompanyGroup: 'Company group',
    PublicContracts: 'Public tenders',
    LocalUnits: 'Branches',
    CompanyStatus: 'Company Status',
    Title: 'Company Summary',
    Address: 'Headquarter address',
    RegisteredOffice: 'Registered office address',
    Town: 'Town',
    State: 'Country',
    TaxCode: 'Tax code',
    TaxNumber: 'Tax number',
    VatCode: 'VAT Number',
    VatNumber: 'VAT Number',
    CrifNumber: 'CRIF number',
    OnrNumber: 'ONR Number',
    CCIAAREA: 'CCIAA / REA',
    TotalNumberBranches: 'Number of branches',
    TotalNumberLocalUnit: 'Number of Local Units',
    DetailedLegalForm: 'Detailed legal form',
    Ateco2007: 'Primary Ateco (2007)',
    SIC: 'SIC',
    NACE: 'Nace',
    StartDate: 'Start date',
    IncorporationDate: 'Incorporation date',
    RegistrationCamComDate: 'Registration date at the Chamber of Commerce',
    HoldingCompanyName: 'Holding company name',
    NationalParentCompany: 'National parent company',
    TenderWon3y: 'Public tenders won (Last 3Y)',
    TenderParticipated3y: 'Public tenders participated (Last 3Y)',
    TenderValueWon3y: 'Public tenders value won  (Last 3Y)',
    TypeOffice: 'Branch Type',
    CountOffice: 'Number of branches by type',
    Turnover: 'Turnover',
    GroupTurnover: 'Group Turnover',
    Estimated: 'Estimated',
    Profit: 'PROFIT',
    Employees: 'Employees',
    Mol: 'MOL',
    DetectionDate: 'Balance sheet date',
    Ebitda: 'EBITDA',
    Ebit: 'EBIT',
    GroupVatCode: 'Company group VAT',
    MainIndustrialSectors: 'Main industrial sectors',
    SecondaryIndustrialSectors: 'Secondary industrial sectors',
    StartOfBusiness: 'Start of business',
    DateOfFoundation: 'Date of foundation',
    TotalNumberOfVehicles: 'Total number of vehicles',
    NumberOfPassengerCars: 'Number of passenger cars',
    CarpoolNumberOfLorries: 'Carpool - number of lorries',
    tlcFix: 'Expenditure on TLC (phone) (€)',
    tlcMob: 'Expenditure on TLC (mobile phone) (€)',
    tlcFixTrend: 'Trend for expenditure on TLC (phone) (%)',
    tlcMobTrend: 'Trend for expenditure on TLC (mobile phone) (%)',
    SemanticClusters: 'Margo Semantic Clusters',
    SemanticCluster: {
      Tooltip: 'Your company does not belong to assigned Semantic Cluster?',
      TooltipNotAssigned: 'Do you want to assign a Semantic Cluster to your company?',
      NoSemanticCluster: 'No Semantic Cluster assigned to this company.',
      Text: 'Contact us to replace the Semantic cluster assigned to your company.',
      TextNoClusterAssigned: 'Contact us to assign a Margo Semantic Cluster to your company.',
      CompanyName: 'Company name',
      IdentificationCode: 'VAT Code, Tax Code, CRIF Number',
      SemanticCluster: 'Semantic cluster',
      ConfirmationDialogTitle: 'Margo Semantic Clusters',
      ConfirmationDialogWarning: 'Request sent successfully',
      ConfirmationDialogText:
        'We have taken care of your request. We will evaluate the replacement of the Semantic Cluster associated with your company.',
      ContactUs: 'Contact us',
      Source: 'Source',
      SourcePlaceholder: 'Insert a website to proof the assigned Semantic Cluster',
      SemanticClusterPlaceholder: 'Select the Semantic Cluster that best suits your company'
    },
    BankInformation: 'Bank Information',
    Grants: 'Grants',
    Vehicles: 'Vehicles',
    ratingScore: 'Rating score',
    ImportExport: 'Import/Export',
    LineOfBusiness: 'Line of business',
    BankAndInsurance: 'Bank and Insurance',
    Registrations: 'Registrations',
    AuditingCompany: 'Auditing company',
    Group: 'Group',
    IsoCodes: 'ISO Certifications',
    StateAids: {
      Title: 'State aids',
      Tooltip: 'The information regarding State Aids refers to the last two years',
      Category: 'Category',
      Amount: 'Amount',
      Transferor: 'Transferor'
    },
    DunsNumber: {
      Headquarter: 'Headquarter DUNS Number',
      RegisteredOffice: 'Registered Office DUNS Number'
    },
    EmployeesOnPermanentContract: 'Percentage of employees on permanent contract',
    EmployeesOnFixedTermContract: 'Percentage of employees on fixed-term contract',
    EmployeesOnFullTimeContract: 'Percentage of employees on full-time contract',
    EmployeesOnPartialTimeContract: 'Percentage of employees on part-time contract',
    EmployeesStatistics: 'Employees statistics',
    EmployeesQualification: 'Employees qualifications',
    EmployeesWithQualificationDirector: 'Percentage of employees with qualification "Director"',
    EmployeesWithQualificationFrameworkContract: 'Percentage of employees with qualification "Framework contract"',
    EmployeesWithQualificationEmployee: 'Percentage of employees with qualification "Employee"',
    EmployeesWithQualificationApprentice: 'Percentage of employees with qualification "Apprentice"',
    EmployeesWithQualificationWorker: 'Percentage of employees with qualification "Worker"',
    EmployeesWithQualificationOtherEmployees: 'Percentage of employees with qualification "Other employees"',
    CommercialDisplay: 'Commercial Display',
    FubIndexLabel:
      'We point out that after the checks carried out by Cribis D&B pursuant to D.P.R. No. 178/2010, this information is present into the Public Register of Oppositions for the specific type.',
    Entrepreneurship: 'Entrepreneurship',
    SoaCertification: 'SOA Certification'
  },
  FinancialStatement: {
    Title: 'Financial Statement',
    Subtitle: 'Main budget indicators for the last 3 years',
    Text: 'Complete details, balance sheet items are available downloading the complete list.',
    ProfitAndLossStatement: 'Items from the profit and loss statement (performance)',
    BalanceSheet: 'Balance sheet items (financial stability)',
    RentabilityIndicators: 'Rentability indicators',
    EconomicData: 'Economic data',
    FinancialData: 'Financials data',
    FinancialRatios: 'Budget indicators',
    ProductionValue: 'Production value',
    ProductionCosts: 'Production costs',
    BalanceOfRevenues: 'Balance of revenues and financial charges',
    ProfitLoss: 'Profit / Loss',
    CashFlow: 'Cash Flow',
    Assets: 'Assets',
    TotalAssets: 'Total assets',
    FixedAssets: 'Fixed assets',
    TangAssets: 'Tangible assets',
    CurrentAssets: 'Current assets',
    TotalLiabilities: 'Total liabilities',
    Liabilities: 'Liabilities',
    LiabilitiesAccruals: 'Liabilities accruals',
    TotalLiabilitiesAndEquity: 'Total liabilities and equity',
    ShareholdersEquity: "Shareholder's equity",
    Provisions: 'Provisions',
    DebtRatio: 'Debt ratio',
    PFN: 'PFN',
    TotalCredits: 'Total credits',
    TotalDebts: 'Total debts',
    NetWorth: 'Net worth',
    EBITDA: 'EBITDA',
    ROE: 'ROE (Profit/Loss for the year / Net Worth)',
    ROI: 'ROI (MON / Total assets)',
    ROS: 'ROS (MON / Production value)',
    TotalEmployees: 'Total employees',
    Turnover: 'Turnover',
    Profit: 'Profit',
    RegisterCapital: 'Registered capital',
    BalanceSheetRatios: 'Balance sheet ratios',
    Sales: 'Sales',
    Cost: 'Cost',
    FinancialAssets: 'Financial assets',
    BalanceSheetProfitLost: 'Balance sheet profit/lost (+/-)',
    Equity: 'Equity',
    LiquidityRatio: 'Liquidity ratio',
    ReturnOnTotalCapital: 'Return on total capital',
    BalanceSheetRatio: 'Balance Sheet Ratio',
    TurnoverAndTurnoverTrend: 'Turnover and turnover trend',
    TurnoverTrend: 'Turnover trend',
    EmployeeEmployeetrend: 'Employee & employee trend',
    EmployeesTrend: 'Employees trend',
    RequestBalance: 'Request Balance',
    InvestmentAssets: 'Investment assets',
    ImmaterialAssets: 'Immaterial assets',
    PropertyEquipment: 'Property equipment',
    Inventories: 'Inventories',
    ReceivablesAndOtherAssets: 'Receivables and other assets',
    CashInhandBalancesWithCreditInstitutions: 'Cash inhand balances with credit institutions',
    FinancialInvestments: 'Financial investments',
    Securities: 'Securities',
    Accruals: 'Accruals',
    CapitalReserves: 'Capital reserves',
    ProfitReserves: 'Profit reserves',
    AccumulatedProfitLoss: 'Accumulated profit loss',
    UntaxedReserves: 'Untaxed reserves'
  },
  People: {
    Title: 'People',
    From: 'From',
    Amount: 'Amount',
    Exponents: 'Directors',
    Shareholders: 'Shareholders',
    Partners: 'Affiliate Companies',
    SupervisoryBoard: 'Supervisory board & statutory body',
    Managers: 'Managers',
    RelatedCompanies: 'Related Companies',
    Owners: 'Owners',
    OwnershipInterests: 'Ownership interests in companies',
    Years: 'years',
    OfficialSourceData: 'Data from official source',
    UnofficialSourceData: 'Data from unofficial source',
    DateOfBirth: 'Date of birth',
    TypeOfConnection: 'Type of connection',
    GoToLinkedin: 'Go to LinkedIn',
    Visualize: {
      PersonData: ' Visualize person data',
      CompanyData: 'Visualize company data'
    }
  },
  DecisionMakersShareholders: {
    Title: 'Decision Makers & Shareholders',
    DecisionMakers: 'Decision Makers',
    ShareholderCompanies: 'Shareholders (Company)',
    ShareholderPersons: 'Shareholders (Person)'
  },
  ContactsAndSocial: {
    Title: 'Contacts & Social',
    Social: 'Social',
    RepresentativeCompany: 'Representative Company',
    CertifiedMail: 'Certified email (PEC)',
    PublicEmail: 'Public email from ESL',
    Website: 'Website URL',
    Ecommerce: 'E-commerce',
    Fax: 'Fax',
    Telephone: 'Telephone',
    Facebook: 'Facebook',
    Twitter: 'Twitter',
    Youtube: 'Youtube',
    Linkedin: 'LinkedIn',
    Pinterest: 'Pinterest',
    Vimeo: 'Vimeo',
    LoadMore: 'Load More',
    ManagerContacts: 'Manager Contacts'
  },
  ContactsAndNews: {
    Title: 'Contacts and news'
  },
  MyContacts: {
    Title: 'My Contacts'
  },
  Analytics: {
    Title: 'Analytics',
    Seasonalit: 'Seasonality score',
    Propensity: 'Propensity score',
    AnalyticsMarketing: 'Analytics Marketing',
    AnalyticsFinance: 'Analytics Finance',
    AnalyticsMarketingMobile: 'Marketing ',
    AnalyticsFinanceMobile: 'Finance',
    SeasonalitWinter: 'Winter',
    SeasonalitSpring: 'Spring',
    SeasonalitSummer: 'Summer',
    SeasonalitAutumn: 'Autumn',
    PropensityShortTerm: 'Short term',
    PropensityMiddTerm: 'Medium term',
    PropensityCarLeas: 'Car lease',
    PropensityInstrLeas: 'Equipment lease',
    PropensityRealEstateLeas: 'Real estate lease',
    PropensityFactoring: 'Factoring',
    Internazionalization: 'Internationalization score',
    Social: 'Social score',
    DigitalAttitude: 'Digital attitude score',
    Innovation: 'Innovation score',
    Supplier: 'Supplier score',
    EstimatedTurnover: 'Estimate turnover',
    AnalyticsMarketingTooltip: 'Analytics Marketing',
    AnalyticsFinanceTooltip: 'Analytics Finance',
    AnalyticsMarketingMobileTooltip: 'Marketing ',
    AnalyticsFinanceMobileTooltip: 'Finance',
    SeasonalitTooltip:
      'Shows the season of the year when the companies are likely to subscribe to short-term credit. Helps to identify prospects or customers and to plan commercial campaigns in certain periods.',
    PropensityTooltip:
      'Shows the propensity of a company to subscribe to a credit product: short-term credit, long-tern credit, factoring, leasing (auto, equipment and machinery, real estate). The propensity score of each product is ranked in 3 levels. (1 = High, 2 = Medium, 3 = Low)',
    InternazionalizationTooltip:
      'Shows the propensity of a company to do international trade.\nThe score ranges from 1 (High propensity) to 5 (Low propensity)',
    SocialTooltip: 'Score social',
    DigitalAttitudeTooltip:
      'Estimates the digital affinity of a company, the propensity to adopt digital processes (ex: digital onboarding) and the responsiveness to digital marketing campaigns.\nThe score ranges from 1 (High propensity) to 5 (Low propensity)',
    InnovationTooltip:
      'Estimates the innovation level of a company, which is correlated with its growth.\nThe score ranges from 1 (High innovation) to 5 (Low innovation)',
    SupplierTooltip: 'Score fornitori',
    EstimatedTurnoverTooltip:
      'For companies that do not have financial statements, an estimate model has been developed that is able to reconstruct the latest turnover.',
    TelcoFixScore: 'Phone consumption score',
    TelcoMobScore: 'Mobile phone consumption score'
  },
  Mastercard: {
    Title: 'Retails',
    Business: 'Business',
    BusinessTooltip:
      'Shows the turnover of a company based on a 12 month trend. The score ranges from 1 (High) to 3 (Low).',
    Competitiveness: 'Competitiveness',
    CompetitivenessTooltip:
      'Shows the potential of a company, based on the competitive landscape it operates in. When it is high it means it is among the best in its industry and geographic area. The score ranges from 1 (High) to 3 (Low).',
    Digitalization: 'Digitization',
    DigitalizationTooltip:
      'Shows the development level of the digital channels of a company. The score ranges from 1 (High) to 3 (Low).',
    Premium: 'Premium',
    PremiumTooltip:
      'Shows the impact of high-spending customers for the company, compared to the average for its industry. When this value is high it means that this company attracts higher than average transactions compared to peer companies. The score ranges from 1 (High) to 3 (Low).',
    CommercialPotential: 'Commercial Potential',
    CommercialPotentialTooltip:
      'Shows the commercial potential of the geographic area compared to industry averages. The score ranges from 1 (High) to 3 (Low).',
    CompetitionLevel: 'Competition Level',
    CompetitionLevelTooltip:
      'Shows the concentration of companies in the same industry in the geographic area where the company operates. The score ranges from 1 (High) to 3 (Low).',
    Performance: 'Performance',
    PerformanceTooltip:
      'It indicates how the retailer reacts to the emergency in terms of revenues generation compared to his performances before the lockdown. It ranges from 1 (Good performances) to 5 (Bad performances).',
    Restart: 'Restart',
    RestartTooltip:
      'Indicates whether the retailer resumed its business activities as before the lockdown. It ranges from 1 (Normal trend) to 5 (Closing).'
  },
  Portfolio: {
    Title: 'Portfolio',
    NumberOfCompanies: 'Number of companies',
    CreationDate: 'Creation date',
    LastUpdateDate: 'Last update date',
    AddToPortfolio: 'Add to portfolio',
    SaveCompany: 'Save company',
    SaveOrCreate: 'Do you want to add the company to a portfolio or create a new one?',
    SaveCompanyDesc:
      'The company will be saved automatically with the status "Prospect" in the "Portfolio Management" area'
  },
  SalesTransaction: {
    Title: 'Assignee Feedback',
    Name: 'Name',
    Product: 'Product',
    Assignee: 'Assignee',
    Task: 'Task',
    startDate: 'Start date',
    EndDate: 'End date',
    Notes: 'Notes'
  },
  UnlockView: {
    Title: 'Purchase {{companyName}} Data to unlock this section',
    MissingPortfoliosTitle: 'Here you will find all the portfolios in which the company is present',
    MissingSalesTransactionsTitle: 'Here you will find all the campaigns in which the company is present',
    Subtitle: 'You can unlock this section by purchasing {{companyName}} data',
    Button: 'Unlock {{companyName}} data',
    Tooltip: 'Please provide info message...'
  },
  ModalWindow: {
    Notes: 'Notes',
    Title: 'You can add up to five notes of this company',
    InsertNote: '  Insert a new note (max 250 characters)',
    NewNote: 'Text here a new note',
    Add: 'Add',
    AreYouSure: 'Are you sure you want to delete this note?',
    Yes: 'Yes',
    No: 'No'
  },
  CompanyProfile: 'Company Profile',
  CompanyProfileMobile: 'Company',
  CompanySize: 'Company size',
  CompanySizeMobile: 'Size',
  FinancialStatementSummary: 'Financial Statement Summary',
  FinancialStatementMobile: 'Financial',
  EmployeesData: 'Employees Data',
  EmployeesDataMobile: 'Employees',
  Contacts: {
    Title: 'Contacts'
  },
  News: 'News',
  Close: 'Close',
  LocalUnits: {
    Title: 'Local units',
    StartDate: 'Start date'
  },
  Branches: {
    Title: 'Branches',
    CbNumber: 'CB number',
    Address: 'Address',
    Telephone: 'Telephone'
  },
  Score: {
    Title: 'Custom Index',
    Tabs: {
      Index: 'Custom Index',
      Relevant: 'Relevant events',
      Telco: 'Analytics Telco',
      Analytics: 'Analytics Utilities'
    }
  },
  CadastralData: {
    Title: 'Cadastral Data',
    totalNumberOfOwnProperties: 'Total number of properties owned by the company',
    numberOfResidentialBuildings: 'Residential buildings owned by the company',
    numberOfCommercialBuildings: 'Commercial buildings owned by the company',
    numberOfOtherBuildings: 'Other buildings owned by the company',
    numberOfLands: 'Lands owned by the company'
  },
  Pnrr: {
    Title: 'PNRR',
    Headers: {
      Score: 'PNRR Score Investment',
      Mission: 'Mission',
      Component: 'Component',
      Scope: 'Scope',
      Investment: 'Investment'
    },
    Tenders: {
      Name: 'Tender Name',
      Status: 'Tender Status',
      StartDate: 'Tender Start Date',
      EndDate: 'Tender End Date',
      WebPageUrl: 'Tender Url',
      WebPageUrlTitle: 'Click here for the Tender'
    }
  },
  CustomIndexes: {
    Title: 'Custom Index',
    Tabs: {
      Index: 'Custom Index',
      Warnings: 'Warnings'
    },
    NegativeEventsPresent: 'Negative events present',
    NegativeEventsNotPresent: 'Negative events not present',
    cbiRiskTooltip: 'It estimates a default probability. It ranges from 1 (Low Risk) to 8 (High Risk).',
    commercialCreditAppraisalCribisDBTooltip:
      "It indicates the maximum recommended exposure for commercial trades, based on the company's characteristics.",
    cribisDBRatingTooltip:
      'It indicates the credit risk profile of the company. It ranges from 1 (Low Risk) to 4 (High Risk).',
    cribisScorePATooltip:
      'A credit risk rate for State-owned enterprises. It ranges from 1 (High Risk) to 5 (Low Risk).',
    delinquencyScoreTooltip:
      "It evaluates the probability of payments' delay. It ranges from 1 (High Risk) to 100 (Low Risk).",
    delinquencySectorScoreTooltip:
      "It indicates the probability of payments' delay in a specific industry. It ranges from 1 (High Risk) to 100 (Low Risk).",
    failScoreTooltip:
      'It indicates the company reliability and its debts payback attitude during the next 12 months. It ranges from 1 (High Risk) to 100 (Low Risk).',
    itpRiskTooltip: 'It describes the days of delay (from 0 to 240) of invoiced payments towards its suppliers.',
    governanceRiskIndexGriTooltip:
      'It indicates the risk related to the company governance. It analyzes the Extraordinary Operations, the Legality Rating, the evaluation about the Company Group, the Exponents and Shareholders, the Supervisory and Control Bodies, etc. It ranges from 1 (Low Risk) to 5 (High Risk)',
    paydexCribisDBTooltip:
      'It evaluates the historical performance of payments towards suppliers and it indicates the capability of the company to respect the payment terms. It ranges from 1 (High Risk) to 100 (Low Risk).',
    sectorsFailureScoreTooltip:
      'It indicates the default risk of a company industry. It ranges from 1 (High Risk) to 100 (Low Risk).',
    sectorsPaydexTooltip:
      "It evaluates the historical performance of payments towards the suppliers and indicates the payment's delay risk of the industry. It ranges from 1 (High Risk) to 100 (Low Risk).",
    supplierTooltip:
      'A supplier evaluation index. It measures the quality of suppliers in terms of environmental certifications, the ties with Public Administration, the trends with respect to the benchmark markets. It ranges from 1 (High Quality) to 5 (Low Quality).',
    telcoPhoneConsumptionScoreTooltip:
      'It indicates the usage intensity of landline phone traffic. It ranges from 1 (Very High) to 5 (Very Low).',
    tlcPhoneFastwebTooltip:
      'It indicates the usage intensity of landline phone traffic. It ranges from 1 (Over 250k €) to 5 (Up to 1.5k €).',
    telcoMobilePhoneConsumptionScoreTooltip:
      'It indicates the usage intensity of mobile phone traffic. It ranges from 1 (Very High) to 5 (Very Low).',
    electricityConsumptionScoreTooltip:
      'It indicates the consumption level of electricity. It ranges from 1 (High Consumption) to 5 (Low Consumption).',
    gasConsumptionScoreTooltip:
      'It indicates the consumption level of gas. It ranges from 1 (High Consumption) to 5 (Low Consumption).',
    warningCzSkDescription:
      'Color of warning indicates its significance: moderate negative information is displayed in yellow, highly significant negative information is displayed in red.',
    cribisIndexTooltip:
      'It expresses the stability of the entity calculated on the basis of financial and/or non-financial criteria. The evaluation reaches values in the scale a1 (exceptionally stable subject) to e (very unstable subject or subject in insolvency).',
    electricityScoreA2aTooltip:
      'It indicates the consumption level of electricity, measured in Watt hour (Wh). It ranges from 1 (High Consumption) to 5 (Low Consumption).',
    gasScoreA2aTooltip:
      'It indicates the consumption level of gas, measured in Cubic meters (cm). It ranges from 1 (High Consumption) to 5 (Low Consumption).',
    pnrrMainScores: {
      m1Tooltip:
        'It indicates the PNRR score associated to Mission 1. It ranges between 1 (maximum) and 5 (minimum). WARNING: If you select two or more investments, both companies who are directly beneficiary for various investments and the indirectly involved ones will be visualized, as well as those that are beneficiary for just one investment.',
      m2Tooltip:
        'It indicates the PNRR score associated to Mission 2. It ranges between 1 (maximum) and 5 (minimum). WARNING: If you select two or more investments, both companies who are directly beneficiary for various investments and the indirectly involved ones will be visualized, as well as those that are beneficiary for just one investment.',
      m3Tooltip:
        'It indicates the PNRR score associated to Mission 3. It ranges between 1 (maximum) and 5 (minimum). WARNING: If you select two or more investments, both companies who are directly beneficiary for various investments and the indirectly involved ones will be visualized, as well as those that are beneficiary for just one investment.',
      m5Tooltip:
        'It indicates the PNRR score associated to Mission 5. It ranges between 1 (maximum) and 5 (minimum). WARNING: If you select two or more investments, both companies who are directly beneficiary for various investments and the indirectly involved ones will be visualized, as well as those that are beneficiary for just one investment.',
      mainScoreTooltip:
        'It indicates the PNRR score associated to Synthetic score for PNRR. It ranges between 1 (maximum) and 5 (minimum). WARNING: If you select two or more investments, both companies who are directly beneficiary for various investments and the indirectly involved ones will be visualized, as well as those that are beneficiary for just one investment.'
    }
  },
  CompanyPopup: {
    Title: 'System message',
    CompanyNotFound: 'It was not possible to recover any data for the selected company'
  },
  PersonPopup: {
    Title: 'System message',
    Desc: 'It was not possible to recover any data for the selected person'
  },
  PdfPopup: {
    Title: 'Confirm operation',
    TextEnriched:
      'As soon as the PDF file is created you will receive a notification, and you will be able to download it and save it. Proceed with the operation?',
    TextNotEnriched:
      'In order to download the PDF it is required to enrich the selected company first. The process could take a few minutes. Confirm to proceed.'
  },
  AccessPurchasePage: 'Request Assignment Report',
  CheckSubsidizedFinancing: 'Check Subsidized financing',
  CompanySurveys: {
    FillOutCheckList: 'Fill out a check-list',
    SelectTitle: 'Select a check-list to fill out',
    SurveyInfo: {
      Grey: 'Grey check-lists identify questionnaire ready to be completed.',
      Green: 'Green check-lists identify already completed questionnaire.',
      Yellow: 'Yellow check-lists identify draft questionnaire.'
    },
    UploadFile: 'Upload file',
    UploadFileTooltip: 'Accepted format: {{acceptedFormat}} Max size: {{maxSize}}MB',
    SaveAsDraft: 'Save as draft',
    SaveAndSend: 'Save and send',
    ErrorInUploadDialog: {
      Title: 'Error in upload',
      FormatFileError: 'The format file is not accepted.',
      MaxSizeError: 'File max size has exceeded.',
      NameTooLongError: `File name is too long. Max chars: ${MAX_FILE_NAME_LENGTH}.`
    },
    SavingFailedDialog: {
      Title: 'Saving failed',
      Text: 'Error in saving the check-list. Try again later or contact customer support'
    },
    Confirmation: {
      Title: 'Send check-list to CRM',
      Text1:
        'Attention! By clicking on the "Save and send" button, the questionnaire and its attachments will be sent to the CRM.',
      Text2: 'The questionnaire will no longer be editable. Do you want to proceed?'
    }
  },
  Appointments: {
    Title: 'Appointments'
  },
  Esg: {
    Title: 'ESG',
    UnlockDate: 'ESG Unlock date',
    Sector: {
      Title: 'ESG Sector Scores'
    },
    Unlock: {
      Title: 'Unlock ESG data to view this section',
      Subtitle: 'You can unlock this section by purchasing ESG data',
      TitleNotAllowed: 'Sorry, this section is not currently available',
      SubtitleNotAllowed: 'The related fields will be visible as a Supervisor will unlock them.',
      Button: 'Unlock ESG data'
    },
    Company: {
      Title: 'ESG Company Score',
      Environment: 'Environment',
      Social: 'Social',
      Governance: 'Governance',
      ESGScoreFactors: 'ESG Score Factors',
      ESGTransitionRisks: 'ESG Transition Risks',
      ESGPhysicalRisks: 'ESG Physical Risks'
    },
    PhysicalRisk: {
      acuteRisks: 'ESG Physical Risks - Acute Risks',
      chronicRisks: 'ESG Physical Risks - Chronic Risks',
      combinedRisks: 'ESG Physical Risks - Combined Risks',
      earthquakeRisks: 'ESG Physical Risks - Earthquake Risks'
    },
    Tooltips: {
      emissions:
        'It indicates the environmental impact for greenhouse gases emission in a synthetic score that ranges from 1 (best) to 3 (worst).',
      energyEfficiency:
        'It indicates the level of energy efficiency for operations activities in a synthetic score that ranges from 1 (best) to 3 (worst).',
      waterUsage:
        'It indicates the level of usage for running water in a synthetic score that ranges from 1 (best) to 3 (worst).',
      wasteProduction:
        'It indicates the quantity of generic or dangerous waste produced in a synthetic score that ranges from 1 (best) to 3 (worst).',
      biodiversity:
        'It indicates the level of operations in natural areas in a synthetic score that ranges from 1 (best) to 3 (worst).',
      environmentalHazards:
        'It indicates the physical risk for natural disasters in a synthetic score that ranges from 1 (best) to 3 (worst).',
      communitySociety:
        'It indicates the social impact on local communities in a synthetic score that ranges from 1 (best) to 3 (worst).',
      employeeRelationship:
        'It indicates the relationship quality with employees in a synthetic score that ranges from 1 (best) to 3 (worst).',
      customerRelationship:
        'It indicates the relationship quality with clients in a synthetic score that ranges from 1 (best) to 3 (worst).',
      povertyFamine:
        'It indicates the commitment to guarantee opportunities to economically disadvantaged groups in a synthetic score that ranges from 1 (best) to 3 (worst).',
      humanRights:
        'It indicates the commitment to respect human rights in a synthetic score that ranges from 1 (best) to 3 (worst).',
      transparency:
        'It indicates the transparency on ESG thematic for company practises in a synthetic score that ranges from 1 (best) to 3 (worst).',
      ethicalConsiderations:
        'It indicates the integrity of company behaviors in a synthetic score that ranges from 1 (best) to 3 (worst).',
      strategyRiskManagement:
        'It indicates the strategy on Risk Management in a synthetic score that ranges from 1 (best) to 3 (worst).',
      inclusiveness:
        'It indicates the inclusiveness for women and young people in a synthetic score that ranges from 1 (best) to 3 (worst).',
      revenueTransitionScore:
        'It provides an evaluation for the potential ESG transition impact on revenues. It ranges from 1 (positive impact) to 5 (negative impact).',
      investmentsTransitionScore:
        'It provides an evaluation for the additional investments amount needed for ESG transition. It ranges from 1 (limited increase) to 5 (significant increase).',
      ebitdaTransitionScore:
        'It provides an evaluation on the impact that ESG transition has on the EBITDA. It ranges from 1 (very positive impact) to 5 (negative impact).',
      overallTransitionScore:
        'It provides an evaluation on the impact of the ESG transition on a financial and economical point of view. It ranges from 1 (negligible impact) to 5 (large impact).',
      landslide:
        'It indicates the exposure to the risk of landslides and subside. It ranges from 1 (low risk) and 10 (maximum risk).',
      coldWave:
        'It indicates the exposure to the risk of cold waves. It ranges from 1 (low risk) and 10 (maximum risk).',
      drought: 'It indicates the exposure to the risk of drought. It ranges from 1 (low risk) and 10 (maximum risk).',
      heatWaves:
        'It indicates the exposure to the risk of heat waves. It ranges from 1 (low risk) and 10 (maximum risk).',
      heavyPrecipitation:
        'It indicates the exposure to the risk of heavy precipitation. It ranges from 1 (low risk) and 10 (maximum risk).',
      wildfire:
        'It indicates the exposure to the risk of wildfires. It ranges from 1 (low risk) and 10 (maximum risk).',
      extremeWind:
        'It indicates the exposure to the risk of extreme wind. It ranges from 1 (low risk) and 10 (maximum risk).',
      flood: 'It indicates the exposure to the risk of flood. It ranges from 1 (low risk) and 10 (maximum risk).',
      changingPrecipitation:
        'It indicates the exposure to the risk of changing precipitation. It ranges from 1 (low risk) and 10 (maximum risk).',
      changingTemperature:
        'It indicates the exposure to the risk of changing temperature. It ranges from 1 (low risk) and 10 (maximum risk).',
      changingWindPatterns:
        'It indicates the exposure to the risk of changing wind patterns. It ranges from 1 (low risk) and 10 (maximum risk).',
      soilCoastalErosion:
        'It indicates the exposure to the risk of soil and coastal erosion. It ranges from 1 (low risk) and 10 (maximum risk).',
      heatStress:
        'It indicates the exposure to the risk of heat stress. It ranges from 1 (low risk) and 10 (maximum risk).',
      permafrostThawing:
        'It indicates the exposure to the risk of permafrost thawing. It ranges from 1 (low risk) and 10 (maximum risk).',
      seaLevelRise:
        'It indicates the exposure to the risk of sea level rise. It ranges from 1 (low risk) and 10 (maximum risk).',
      soilDegradation:
        'It indicates the exposure to the risk of soil degradation. It ranges from 1 (low risk) and 10 (maximum risk).',
      waterStress:
        'It indicates the exposure to the risk of water stress. It ranges from 1 (low risk) and 10 (maximum risk).',
      earthquake:
        'It indicates an exposure score for risks posed by earthquake, considering also the local units (in EU27) and the company sector. It ranges from 1 (lowest level of risk) and 10 (highest level of risk).',
      combinedChronic:
        'It groups all chronic phenomena and expresses geographical danger and expected damage for the company, considering also its local units (in EU27) and the company sector. It ranges from 1 (lowest level of risk) to 5 (highest level of risk).',
      combinedAcute:
        'It groups all acute phenomena and expresses geographical danger and expected damage for the company, considering also its local units (in EU27) and the company sector. It ranges from 1 (lowest level of risk) to 5 (highest level of risk).',
      combinedFull:
        'It groups all natural phenomena (chronic, acute and earthquake) and expresses geographical danger and expected damage for the company, considering also its local units (in EU27) and the company sector. It ranges from 1 (lowest level of risk) to 5 (highest level of risk).',
      flagChronicAcute:
        'It considers the exposure to at least one significant risk - Chronic and Acute. It can assume as value 0 (no exposure) or 1 (at least one exposure).',
      scoreE: 'Synthetic indicator of the company about component "Environment". It ranges from 1 (best) to 5 (worst).',
      scoreS: 'Synthetic indicator of the company about component "Social". It ranges from 1 (best) to 5 (worst).',
      scoreG: 'Synthetic indicator of the company about component "Governance". It ranges from 1 (best) to 5 (worst).',
      scoreEsg: 'Overall evaluation of the company about ESG indicators. It ranges from 1 (best) to 5 (worst).',
      sector: {
        scoreE:
          'Synthetic indicator of the related industry about component "Environment". It ranges from 1 (best) to 5 (worst).',
        scoreS:
          'Synthetic indicator of the related industry about component "Social". It ranges from 1 (best) to 5 (worst).',
        scoreG:
          'Synthetic indicator of the related industry about component "Governance". It ranges from 1 (best) to 5 (worst).'
      }
    },
    Table: {
      ScoreFactor: 'Score factor',
      Description: 'Description',
      Score: 'Score'
    }
  },
  UnlockCompanyEsgDataDialog: {
    Title: 'Unlock ESG Score',
    SubTitle: 'Do you want to unlock ESG Score for this company?',
    UnlockText:
      'Data will be unlocked and we will keep it updated for six months from the unlock date, or until the subscription end date if sooner. When the time expires for ESG data of this company, you will have to unlock them again.',
    UnlockingText: 'The unlock operation is in progress, it may take a few seconds.',
    CompanyUnlockedText: 'ESG Score has been successfully unlocked for this company. You can close this dialog now.',
    CompanyNotUnlockedText: 'ESG Score was unsuccessful due to the absence of ESG data for this company.',
    ErrorUnlockingEsgData: 'An error occurred while unlocking ESG data. Please try again later.',
    InsufficientCredits: 'You do not have enough credits to unlock ESG data for this company.'
  }
}
