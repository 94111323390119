import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { SaveBeforeContinue } from '@pages/Prospecting/SaveBeforeContinueDialog'
import UIcon from '../../assets/icons/single.svg?react'
import UserSecret from '../../assets/icons/fa-user-secret.svg?react'
import ChevronRight from '../../assets/icons/chevron-right.svg?react'
import LogoutIcon from '../../assets/icons/icon-s-log-out.svg?react'
import { UserActions } from '../../features/user/actions'
import {
  getUserFullName,
  getUserAccountInfo,
  hasOriginator,
  getOriginatorUserCode,
  hasFreemiumRole
} from '../../features/user/selectors'
import { UserType } from '../../authorization'
import { ACCOUNT } from '../../routes'
import { Text } from '../Text'
import { useChangeCountry } from './hooks'

const Divider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dustyBlue};
  margin: 0 15px;
`

const UserIcon = styled(UIcon)`
  fill: white;
  margin: 15px;
`

const UserSmallIcon = styled(UIcon)`
  fill: white;
  width: 20px;
  height: 20px;
  margin-right: 16px;
`

const UserSecretIcon = styled(UserSecret)`
  fill: white;
  margin: 15px;
`

const Top = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  text-align: start;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
`

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 25px 10px 15px;
  gap: 10px;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;

  svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`

const InfoWrapper = styled.div`
  flex: 1 1 auto;
  padding: 10px 0;
`

const LogoutButton = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;

  svg {
    width: 18px;
    fill: ${({ theme }) => theme.colors.white};
    margin-right: 5px;
  }

  div {
    text-wrap: nowrap;
  }
`

const UserCodeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 0;
`
const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background: none;
  border: none;
  padding: 0;
`

export const AccountInfo = ({ saveBeforeContinue = fn => fn }: SaveBeforeContinue) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const name = useSelector(getUserFullName)
  const accountInfo = useSelector(getUserAccountInfo)
  const isImpersonited = useSelector(hasOriginator)
  const originatorUserCode = useSelector(getOriginatorUserCode)
  const isFreemiumUser = useSelector(hasFreemiumRole)

  const { changeCountry } = useChangeCountry()

  const logout = React.useCallback(() => {
    dispatch(UserActions.logout(false))
  }, [dispatch])

  const goToAccount = saveBeforeContinue(() => navigate(ACCOUNT))

  const userCodeDisplayed = useMemo(() => {
    if (isFreemiumUser) return t('TrialUser')

    return isImpersonited ? originatorUserCode : accountInfo.userCode
  }, [accountInfo.userCode, isFreemiumUser, isImpersonited, originatorUserCode, t])

  return (
    <div id="ta-account-info">
      <StyledButton type="button" onClick={goToAccount}>
        <Top>
          {isImpersonited ? <UserSecretIcon /> : <UserIcon />}
          <InfoWrapper>
            <Text block text={isImpersonited ? `${name} (${accountInfo.userCode})` : name} bold color="dustyBlue" />
            <Text block text={UserType[accountInfo.userType]} bold color="white" />
          </InfoWrapper>
          <IconWrapper>
            <ChevronRight />
          </IconWrapper>
        </Top>
      </StyledButton>
      <Divider />
      <Bottom>
        <UserCodeWrapper>
          {isImpersonited && <UserSmallIcon />}
          <Text block color="dustyBlue" ellipsis>
            {userCodeDisplayed}
          </Text>
        </UserCodeWrapper>
        <LogoutButton onClick={isFreemiumUser ? changeCountry : logout} id="ta-logout">
          <LogoutIcon />
          <Text text={isFreemiumUser ? t('ChangeCountry') : t('Logout')} color="white" block />
        </LogoutButton>
      </Bottom>
    </div>
  )
}
